/**各種ボタン */
import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ to, children, color = '#454CF4', padding = '10px 35px',className }) => {
  const style = {
    backgroundColor: color,
    color: 'white',
    padding: padding,
    border: 'none',
    borderRadius: '37.5px',
    textAlign: 'center',
    
  };

  return (
    <Link to={to} style={style} className={className}>
      {children}
    </Link>
  );
}

export default Button;