// 口が開かない　顎が痛い
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms3() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="口が開かない・顎が痛い" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            食事や会話をする際に、顎を動かすと痛みがでたり、口が開かない・開きにくい、また寝起き時に顎が疲れている、頭が重い、肩が凝るなどの症状がある場合の原因として、顎関節症や顎関節強直症、口腔悪習癖（歯軋りなど）が考えられます。<br />
                            顎関節症の症状が進行すると関節の変形や顎運動の偏位などを引き起こします。<br />
                            口腔悪習癖（歯軋りなど）は軽度であれば問題ありませんが、症状が重度になると、顎関節の変形や偏位、歯のすり減りによる知覚過敏症状、破折などを引き起こします。<br />
                            上記の症状があるようであれば、一度歯科医院を受診し、相談することをお勧めします。<br />
                        </div>
                        <div>
                        <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />

        </div>
    );


};

export default Dentalsymptoms3;