import React, { useState } from 'react';
import '../../styles/components/medicalinformation.scss';
import triangleLine from '../icons/triangleline.svg';
import Button from './externallink';

const MedicalInformation = () => {

    return(
        <div className="medicalinformation">
            <section className="title">
                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">その他診療について</h2>
                </div>
            </section>

            <section className="others">
                <div className="wrapper">
                    <div className="box1">
                        <h3>ナステントについて</h3>
                        <p>鼻チューブでいびきのない睡眠</p>
                        <Button to="https://nastent.co.jp/">ナステント公式サイト</Button>
                    </div>
                    <div className="box1">
                        <h3>陥入爪（巻き爪について）</h3>
                        <p>痛くない巻き爪矯正<br />
                            マルホ株式会社 巻き爪マイスタ
                        </p>
                        <Button to="https://www.maruho.co.jp/kanja/makizume/column/know/002_1">マルホ株式会社</Button>
                    </div>
                    <div className="box3">
                        <h3>ワキ汗について</h3>
                        <p>シャツのワキの汗ジミが気になって好きな服が着れないなど</p>
                        <Button to="https://wakiase-navi.jp/">ワキ汗治療ナビ</Button>
                    </div>
                    <div className="box4">
                        <h3>男性型脱毛症について</h3>
                        <p>　</p>
                        <Button to="https://www.organo.co.jp/">オルガノ株式会社　プロペシア</Button>
                    </div>

                </div>
            </section>

        </div>
    )


}

export default MedicalInformation;