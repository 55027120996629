// 歯の色が気になる
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms8() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="歯の色が気になる" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            歯の変色の原因には、コーヒーやお茶などによる着色、虫歯、薬剤性の着色、金属イオンの流出、歯の神経の壊死や根管治療後の歯の経年変化など様々な原因が挙げられます。<br />
                            原因によって対処法は変わりますので、歯科医院で相談することをお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />

        </div>
    );


};

export default Dentalsymptoms8;