import React from 'react';
import backgroundImage from "../img/background-img.png";
import '../../styles/components/imagewithtitle.scss';

const containerStyle = {
    display: 'flex',
    position: 'relative', // 追加
    backgroundImage: `linear-gradient(269deg, #D6D6D6 63.53%, rgba(217, 217, 217, 0.12) 99.41%)`
};

const imageContainerStyle = {
    width: '40%',
    display: 'flex',
    zIndex: '-100',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover'
};

const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
};

const titleStyle = {
    fontWeight: 'bold',
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', 
    position: 'absolute', // 追加
    top: '50%', // 追加
    left: '50%', // 追加
    transform: 'translate(-50%, -50%)', // 追加
    zIndex: 1
};

const ImageWithTitle = ({ title }) => (
    <div className="imgtitlecontainer" style={containerStyle}>
        <div style={imageContainerStyle}>
            <img src={backgroundImage} alt="Background" style={imageStyle} />
        </div>
        {/* titleをコンテナ全体の中央に配置 */}
        <span style={titleStyle}>{title}</span>
        {/* こちらは画像の右側の60%の空間となる部分 */}
        <div style={{ width: '60%' }}></div>
    </div>
);

export default ImageWithTitle;
