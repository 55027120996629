// 噛むと痛い
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms2() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="噛むと痛い" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            食事をした時や噛み込んだ時に歯が痛む、温かい物を含むと痛む、違和感があるなどの原因として、歯の根の先に膿の袋ができている、あるいは神経が壊死しかけている可能性があります。<br />
                            症状が進行すると膿の袋が大きくなり、歯茎や顎が腫れたり、熱発したり、歯が揺れてきて抜歯になってしまう可能性もあります。<br />
                            早期に神経の処置（根管治療）を開始することで抜歯を回避できるケースも多いため、早めに受診することをお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />

        </div>
    );


};

export default Dentalsymptoms2;