// 口臭が気になる
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms10() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="口臭が気になる" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            口臭の主な原因は、口腔清掃不良や歯周炎、虫歯が挙げられます。<br />
                            磨き残し（歯垢、プラーク）や歯石は細菌の塊です。<br />
                            また舌苔（舌の上の汚れ）も口臭の原因となります。<br />
                            その他、虫歯や歯周炎、根管治療途中の歯なども口臭の原因となります。<br />
                            定期検診や治療が必要になりますので、受診をお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />

        </div>
    );


};

export default Dentalsymptoms10;