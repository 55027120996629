/**審美歯科 */
import React, { useState , useRef } from 'react';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/dental/dental-periodontal.scss';
import triangleLine from '../icons/triangleline.svg';

function aesthetic (){
    return (
        <div>
            <ImageWithTitle title="審美治療"/>

            <div className="main">
                <p>
                    近年、美容医療が注目され、歯並びや歯の形、色など審美治療も関心が高まっています。<br />
                    歯の形が気になる、銀歯を白くしたい、ホワイトニングしたけどあまり白くならないなど、口元のお悩みは多くあると思います。<br />
                    口元は第一印象に大きく関係しています。ですが、見た目が綺麗でも、食事しにくい、噛めない、詰め物・被せ物が割れてしまうなど、機能面が疎かになってしまっては、生活の質の向上は得られません。<br />
                    当院では審美面はもちろんのこと、機能面も確保した審美治療に重点を置いています。<br />
                    十分なカウンセリングを行い、患者様のご要望に添いながら、理想的な治療を目指します。
                </p>

                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">審美治療における主な使用材料の種類</h2>
                </div>

                <div>
                    <h3>
                        ジルコニア
                    </h3>
                    <p>
                        最も強度に優れる材料です。<br />
                        強度が高いため、歯軋りや咬合力の強い方に特におすすめです。<br />
                        白色で、変色しない特徴があります。<br />
                        また当院ではジルコニアに特殊な加工を施すことで、被せ物の種類によってはオールセラミックに負けない色調を再現しているものもあります。
                    </p>
                </div>
                
                <div>
                    <h3>
                        e-max (オールセラミック)
                    </h3>
                    <p>
                        最も色調に優れる材料です。<br />
                        白色で、透明度が高いため、前歯などのスマイルラインや下の歯の見えやすい部位の治療におすすめです。<br />
                        ジルコニアに比べ、やや強度が下がるため、奥歯の適応には向かない場合もあります。
                    </p>
                </div>

                <div>
                    <h3>
                        メタルボンド
                    </h3>
                    <p>
                        金属フレーム上にセラミックを加工した材料です。<br />
                        色調や噛み合わせの調整に優れます。<br />
                        金属フレームを使用している関係上、経年変化により歯茎の付け根が黒く見えてきてしまうことがあります。
                    </p>
                </div>

                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">料金表</h2>
                </div>

                <p>
                    部分的な被せ物(インレー)<br />
                    ジルコニアインレー 45000円<br />
                    e-maxインレー    45000円<br />
                    <br />
                    <span className="red">＊税抜き価格 別途型取り代3000円がかかります</span><br />
                    <span className="red">＊当院では2年間の保証期間を設けています</span><br />
                    <br />
                    歯全体を覆う被せ物(クラウン)<br />
                    ジルコニアクラウン 100000〜120000円 (加工の種類によります)<br />
                    e-maxクラウン    80000円<br />
                    メタルボンドクラウン 90000円<br />
                    <br />
                    <span className="red">＊税抜き価格 別途型取り代3000円かかります</span><br />
                    <span className="red">＊当院では5年間の保証期間を設けております</span><br />
                </p>

                <h3>その他、自費診療もございますので、お気軽にご相談ください</h3>

                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">リスク・注意点</h2>
                </div>

                <p>
                    被せ物の治療は歯の形を100%元通りにする治療ではありません<br />
                    また、以前と全く同じ噛み合わせにならないこともあります<br />
                    使用材料によってはアレルギー反応を示す可能性もあります<br />
                </p>
            </div>
        </div>
    );
}

export default aesthetic ;