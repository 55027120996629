// その他　/** 根幹治療 */
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms13() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="根管治療" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-box">
                        <div className="container-box__text">
                            根管治療とは虫歯が大きく、歯髄（歯の神経）に到達している際に行う治療です。
                            <br />
                            根管治療は、最終的に治療が完了するまで６〜７回の治療回数がかかりますが、途中で治療を放置してしまうと抜歯になってしまうなどのリスクが伴います<br />根管治療を始めたら、被せ物の接着までしっかりと通院をするようにしましょう。<br />
                            また、被せ物について自費治療の希望がある場合や悩んでいる場合は、事前に先生にご相談ください。
                            <br />
                            <br />
                            【根管治療の流れ】
                            <br />
                            （虫歯の除去・根管治療）<br />
                            まずは虫歯を取り除いてから虫歯によって感染してしまった歯髄を取り除きます。<br />
                            治療回数は虫歯の大きさや、歯髄の感染の程度によって変わってきます。<br />
                            個人差、感染の程度によりおよそ３〜４回かかります<br />
                            <br />
                            ↓ （根管充填治療）<br />
                            歯髄を取り除いた後は、歯髄があったところが空洞状態になるため<br />
                            歯髄の代わりになる材料を詰めます。<br />
                            <br />
                            ↓ （コア形成）<br />
                            歯の土台を立てます<br />
                            残った歯の状態により土台の立て方は変わります<br />
                            （歯がほとんど残っていない場合、土台を作るための型取りが必要になる場合があります）<br />
                            （自費の被せ物を使用する場合専用の土台を使います（自費料金￥3000+税がかかります））<br />
                            治療回数は１〜２回<br />
                            <br />
                            ↓ （形成・印象）<br />
                            被せ物の準備をしていきます、最終的には歯とほぼ同じ大きさの被せ物による修復となります<br />
                            土台の形を綺麗に整え、型取りをさせていただきます<br />
                            被せ物の種類は保険（銀歯）と自費と種類があるため、患者様ご自身で選んでいただくことができます<br />
                            被せ物の種類については「自費治療について（歯全体を覆う被せ物）」をご覧ください<br />
                            治療回数は１〜２回<br />
                            <br />
                            ↓ （被せ物の接着）<br />
                            出来上がってきた被せ物を接着させていただきます<br />
                            噛み合わせなどの調節をさせていただき治療が終了となります<br />
                            治療回数は１回<br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

        </div>
    );


};

export default Dentalsymptoms13;