/**歯科施設・設備紹介 */
import React, { useState } from 'react';
import '../../styles/dental/dental-introduction.scss';
import ImageWithTitle from '../components/imagewithtitle';
import triangleLine from '../icons/triangleline.svg';
import Access from '../components/access';
import reception from '../img/受付.JPG';
import waitingroom from '../img/待合室.JPG';
import corridor from '../img/廊下.JPG';
import examinationroom from '../img/診察室.JPG';
import anesthesia from '../img/電動麻酔器.JPG';
import laser from '../img/口腔内レーザー.JPG';
import airflow from '../img/エアフロー.JPG';
import Xray from '../img/レントゲン.JPG';
import img1 from '../img/口腔外バキューム.JPG';
import img2 from '../img/ジェットウォッシャー.JPG';
import img3 from '../img/サーボクレーブ.JPG';
import img4 from '../img/オートクレーブ.JPG';
import img5 from '../img/ポセイドン.JPG';
import img6 from '../img/感染対策.JPG';








function DentalIntroduction () {

    return (
        <div className="dentalintroduction">

            <ImageWithTitle title="施設・設備紹介" />

            <div className="for-pc">
                <div className="gallery">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">院内の紹介</h2>
                    </div>
                    <div className="gallery-contents">
                        <div className="imgbox">
                            <img src={reception}/>
                        </div>
                        <div className="textbox">
                            <h3>受付</h3>
                            <p>
                                当院スタッフによる手書きのお知らせなどが並びます。<br />
                                患者様に気軽にお申し付けいただけるような雰囲気作りを心がけております。
                            </p>
                        </div>
                    </div>
                    <div className="gallery-contents">
                        <div className="textbox">
                            <h3>待合室</h3>
                            <p>当院では、医科も併設しており、心地よく患者様にお待ちいただけるよう医科の待合室も利用できるように解放しております。</p>
                        </div>
                        <div className="imgbox">
                            <img src={waitingroom}/>
                        </div>
                    </div>
                    <div className="gallery-contents">
                        <div className="imgbox">
                            <img src={corridor}/>
                        </div>
                        <div className="textbox">
                            <h3>廊下</h3>
                            <p>赤、黄色、青のカラーで統一された診察室の扉が並びます。</p>
                        </div>
                    </div>
                    <div className="gallery-contents">
                        <div className="textbox">
                            <h3>診察室</h3>
                            <p>
                                当院は明るく清潔な院内を保つことを心がけております。<br />
                                患者さまには見えない部分ではありますが、整った衛生状態で常に最高の治療を提供し、安心して治療を受けていただくための当院の方針です。
                            </p>
                        </div>
                        <div className="imgbox">
                            <img src={examinationroom}/>
                        </div>
                    </div>



                </div>
            </div>
            <div className="for-sp">
                <div className="gallery">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">院内の紹介</h2>
                    </div>
                    <div className="gallery-contents">
                        <div className="imgbox">
                            <img src={reception}/>
                        </div>
                        <div className="textbox">
                            <h3>受付</h3>
                            <p></p>
                        </div>
                    </div>
                    <div className="gallery-contents">
                        <div className="imgbox">
                            <img src={waitingroom}/>
                        </div>
                        <div className="textbox">
                            <h3>待合室</h3>
                            <p></p>
                        </div>
                    </div>
                    <div className="gallery-contents">
                        <div className="imgbox">
                            <img src={corridor}/>
                        </div>
                        <div className="textbox">
                            <h3>廊下</h3>
                            <p></p>
                        </div>
                    </div>
                    <div className="gallery-contents">
                        <div className="textbox">
                            <div className="imgbox">
                                <img src={examinationroom}/>
                            </div>
                            <h3>診察室</h3>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="equipment">
                <div className="for-pc">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">診察に使用する機器などの紹介</h2>
                    </div>
                    <div className="equipment-white">
                        <div className="imgwithtext">
                            <div className="imgwrapper">
                                <img src={anesthesia}/>
                            </div>
                            <p>電動麻酔機</p>
                        </div>
                        <div className="imgwithtext">
                            <div className="imgwrapper">
                                <img src={laser}/>
                            </div>
                            <p>口腔内レーザー</p>
                        </div>
                        <div className="imgwithtext">
                            <div className="imgwrapper">
                                <img src={airflow}/>
                            </div>
                            <p>エアフロー</p>
                        </div>
                        <div className="imgwithtext">
                            <div className="imgwrapper">
                                <img src={Xray}/>
                            </div>
                            <p>CTレントゲン機器</p>
                        </div>
                    </div>
                </div>

                <div className="for-pc">
                    <div className="equipment-2">
                        <div className="title">
                            <img className="title__picture" src={triangleLine} alt="" />
                            <h2 className="title__text">院内設置機器などの紹介</h2>
                        </div>
                        <div className="equipment-2-white">
                            <div className="flex-container">
                                <div className="imgwrapper">
                                    <img src={img1}/>
                                </div>
                                <div className="text-wrapper">
                                    <h3>口腔外バキューム<br />
                                        FREE ARM ARTEO
                                    </h3>
                                    <p>治療時に口腔外に漏れる有害な浮遊粉塵を発生源から捕集する院内感染対策に有効な歯科用吸引装置を導入しています</p>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="text-wrapper">
                                    <h3>Miele ジェットウォッシャー</h3>
                                    <p>熱水消毒において、ウォッシャーディスインフェクターに関する国際規格(ISO15883)に準拠した高性能洗浄機による歯科用器具の洗浄と消毒を行なっています</p>
                                </div>
                                <div className="imgwrapper">
                                    <img src={img2}/>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="imgwrapper">
                                    <img src={img3}/>
                                </div>
                                <div className="text-wrapper">
                                    <h3>高温高圧滅菌器 サーボクレーブ</h3>
                                    <p>コンピューター制御により滅菌・乾燥温度を管理し確実な減菌を行っています</p>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="text-wrapper">
                                    <h3>歯科用オートクレーブ
    DAC UNIVERSAL</h3>
                                    <p>最新型のオートクレーブ滅菌器による衛生基準を満たした管理で、院内での交叉感染を防ぎますタービン・ハンドピース等(歯を削る器械)を１本１本洗浄し、注油、減菌を短時間に行うので、衛生的な機器による治療を患者様に安心して受けていただけます</p>
                                </div>
                                <div className="imgwrapper">
                                    <img src={img4}/>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="imgwrapper">
                                    <img src={img5}/>
                                </div>
                                <div className="text-wrapper">
                                    <h3>歯科ユニットウォーターライン
    除菌装置　Poseidon</h3>
                                    <p>「ポセイドン」は、残留塩素濃度を補正し給水管路内の除菌能力を維持することで患者様に衛生的な給水環境をご提供しますポセイドンを通した水は院内全域に用いられます</p>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="text-wrapper">
                                    <h3>清潔な器具と使い捨て器具での感染対策</h3>
                                    <p>治療時に使用するタービン・エンジン（切削器具）等やその他の機械・器具は、1本1本患者様ごとに消毒済みの清潔な滅菌パックを用い、診察で着用した手袋、紙コップ、エプロンは使い捨てることによって感染を防ぎます</p>
                                </div>
                                <div className="imgwrapper">
                                    <img src={img6}/>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div className="for-sp">
                    <div className="equipment-2">
                        <div className="title">
                            <img className="title__picture" src={triangleLine} alt="" />
                            <h2 className="title__text">院内設置機器などの紹介</h2>
                        </div>
                        <div className="equipment-2-white">
                            <div className="flex-container">
                                <div className="imgwrapper">
                                    <img src={img1}/>
                                </div>
                                <div className="text-wrapper">
                                    <h3>口腔外バキューム<br />
                                        FREE ARM ARTEO
                                    </h3>
                                    <p>治療時に口腔外に漏れる有害な浮遊粉塵を発生源から捕集する院内感染対策に有効な歯科用吸引装置を導入しています</p>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="imgwrapper">
                                    <img src={img2}/>
                                </div>
                                <div className="text-wrapper">
                                    <h3>Miele ジェットウォッシャー</h3>
                                    <p>熱水消毒において、ウォッシャーディスインフェクターに関する国際規格(ISO15883)に準拠した高性能洗浄機による歯科用器具の洗浄と消毒を行なっています</p>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="imgwrapper">
                                    <img src={img3}/>
                                </div>
                                <div className="text-wrapper">
                                    <h3>高温高圧滅菌器 サーボクレーブ</h3>
                                    <p>コンピューター制御により滅菌・乾燥温度を管理し確実な減菌を行っています</p>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="text-wrapper">
                                    <div className="imgwrapper">
                                        <img src={img4}/>
                                    </div>
                                    <h3>歯科用オートクレーブ
    DAC UNIVERSAL</h3>
                                    <p>最新型のオートクレーブ滅菌器による衛生基準を満たした管理で、院内での交叉感染を防ぎますタービン・ハンドピース等(歯を削る器械)を１本１本洗浄し、注油、減菌を短時間に行うので、衛生的な機器による治療を患者様に安心して受けていただけます</p>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="imgwrapper">
                                    <img src={img5}/>
                                </div>
                                <div className="text-wrapper">
                                    <h3>歯科ユニットウォーターライン
    除菌装置　Poseidon</h3>
                                    <p>「ポセイドン」は、残留塩素濃度を補正し給水管路内の除菌能力を維持することで患者様に衛生的な給水環境をご提供しますポセイドンを通した水は院内全域に用いられます</p>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="imgwrapper">
                                    <img src={img6}/>
                                </div>
                                <div className="text-wrapper">
                                    <h3>清潔な器具と使い捨て器具での感染対策</h3>
                                    <p>治療時に使用するタービン・エンジン（切削器具）等やその他の機械・器具は、1本1本患者様ごとに消毒済みの清潔な滅菌パックを用い、診察で着用した手袋、紙コップ、エプロンは使い捨てることによって感染を防ぎます</p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>


            </div>

            <Access />

        </div>


    );




};

export default DentalIntroduction;