/*特殊診療ページ*/
import React from 'react';
import TreatmentSchedule from '../components/schedule';
import MedicalInformation from '../components/medicalinformation';



// アイコンインポート
import image1 from '../icons/外科.svg';
import image1hover from '../icons/外科ホバー.svg';
import image2 from '../icons/内科.svg';
import image2hover from '../icons/内科ホバー.svg';
import image3 from '../icons/胃腸科.svg';
import image3hover from '../icons/胃腸科ホバー.svg';
import image4 from '../icons/乳腺・甲状腺専門.svg';
import image4hover from '../icons/乳腺、甲状腺専門ホバー.svg';
import image5 from '../icons/整形外科.svg';
import image5hover from '../icons/整形外科ホバー.svg';
import image6 from '../icons/肛門科.svg';
import image6hover from '../icons/肛門科ホバー.svg';
import image7 from '../icons/訪問診療.svg';
import image7hover from '../icons/訪問診療ホバー.svg';
import image8 from '../icons/在宅支援.svg';
import image8hover from '../icons/在宅支援ホバー.svg';
import image9 from '../icons/icons-産業医.png';
import image9hover from '../icons/icons-産業医2.png';
import image10 from '../icons/icons-特殊診療.png';
import image10hover from '../icons/icons-特殊診療2.png';
import image11 from '../icons/icons-発熱.png';
import image11hover from '../icons/icons-発熱2.png';

function MedicalUnique (){

    return (
        <div>
            <MedicalInformation />
        </div>
    );
}

export default MedicalUnique;