/**整形外科 */
import React from 'react';
import TreatmentSchedule from '../components/schedule';
import MedicalInformation from '../components/medicalinformation';
import ImageWithTitle from '../components/imagewithtitle';
import img from '../img/orthopedics.JPG';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import '../../styles/medical/medical-surgery.scss';
import ButtonBox from '../components/buttonbox';

function MedicalOrthopedics () {
    return(
        <div className="medicalsurgery">
            <ImageWithTitle title="整形外科"/>

            <section className="main">
                <div className="box">
                    <div className="img">
                        <img src={img}></img>
                    </div>
                    <p>骨粗しょう症、痛風、打撲、捻挫、肩こり、筋肉痛、ひざの痛み、神経痛など
                    </p>
                </div>
            </section>

            {/* 3. 科目別で探すセクション */}
            <section className="medical-top__departments">
                <h2 className="title__text">科目別で探す</h2>
                <ButtonBox />
                <div className="medical-top__img">
                <img src={triangleMatrix} className="medical-top__img--topleft" />
                <img src={triangleMatrix} className="medical-top__img--bottomright" />
                </div>
            </section>

            <TreatmentSchedule />

            <MedicalInformation />
        </div>

    );

}

export default MedicalOrthopedics;