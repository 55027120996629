/**　診療のご案内 */
import React, { useState } from 'react';
import Dentalpractice from '../components/dentalpractice';
import Specialtreatment from '../components/specialtreatment';
import DentalSchedule from '../components/dental-schedule';
import Access from '../components/access';
import ImageWithTitle from '../components/imagewithtitle';


function DentalInformation(){
    return(
        <div className="dentalinfomation">
            <ImageWithTitle title="診療のご案内" />

            <Dentalpractice />

            <Specialtreatment />

            <DentalSchedule />

            <Access />
        </div>


    )



};

export default DentalInformation;