import React from 'react';
import { Link } from 'react-router-dom';

const DentalButton = ({ to, children, color = '#EFEF87',className }) => {
  const style = {
    backgroundColor: color,
    color: '#000',
    border: 'none',
    textAlign: 'center',
  };

  return (
    <Link to={to} style={style} className={className}>
      {children}
    </Link>
  );
}

export default DentalButton;