/** 胃腸科　*/
import React from 'react';
import TreatmentSchedule from '../components/schedule';
import MedicalInformation from '../components/medicalinformation';
import ImageWithTitle from '../components/imagewithtitle';
import img from '../img/gastroenterology.JPG';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import '../../styles/medical/medical-surgery.scss';
import ButtonBox from '../components/buttonbox';

function MedicalGastroenterology () {
    return(
        <div className="medicalsurgery">
            <ImageWithTitle title="胃腸科"/>

            <section className="main">
                <div className="box">
                    <div className="img">
                        <img src={img}></img>
                    </div>
                    <p>食道、胃、肝臓、胆のう、すい臓などの消化器全般を診療しております。<br />
                        食欲不振、胸やけ、胃の痛み、胃もたれ、腹痛、体重減少など、気になる症状がございましたらご相談ください。
                    </p>
                </div>
            </section>

            {/* 3. 科目別で探すセクション */}
            <section className="medical-top__departments">
                <h2 className="title__text">科目別で探す</h2>
                <ButtonBox />
                <div className="medical-top__img">
                <img src={triangleMatrix} className="medical-top__img--topleft" />
                <img src={triangleMatrix} className="medical-top__img--bottomright" />
                </div>
            </section>

            <TreatmentSchedule />

            <MedicalInformation />
        </div>

    );

}

export default MedicalGastroenterology;