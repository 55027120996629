/**マウスピース矯正 */
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import triangleLine from '../icons/triangleline.svg';
import Dentalpractice from '../components/dentalpractice';
import Specialtreatment from '../components/specialtreatment';
import DentalSchedule from '../components/dental-schedule';
import Access from '../components/access';
import video_straumann from "../img/dentalcorrection_straumann.mp4";
import video_play from "../icons/play.svg";
import video_stop from "../icons/stop.svg";
import video_replay from "../icons/replay.svg";
import teethrow from "../img/歯並び.png";


function Correction (){
    const videoRef = useRef(null);

    const handlePlayVideo = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    const handleStopVideo = () => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      };
    
    const handleRestartVideo = () => {
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
          videoRef.current.play();
        }
      };
      
    return (
        <div>
            <ImageWithTitle title="マウスピース矯正"/>
            <section className="correction_contents">
                <div className="information">
                    <p>
                        近年、ブラケットやワイヤーといった矯正装置による歯列矯正に加えて、目立ちにくいマウスピース型の矯正装置が注目されています。<br />
                        当院ではストローマン社のクリアコレクトというマウスピース矯正を扱っております。
                    </p>
                </div>

                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">マウスピース矯正の特徴（メリット、デメリット）</h2>
                </div>

                <div className="maincontents">
                    <div className="video">
                        <div className="for-pc">
                            <video
                                ref={videoRef}
                                src={video_straumann}
                                playsInline
                                controls={false}
                            />
                        <div className="buttons">
                            <button class="play" onClick={handlePlayVideo}>
                                <div class="play_img">
                                    <img src={video_play} />
                                </div>
                            </button>
                            <button class="stop" onClick={handleStopVideo}>
                                <div class="stop_img">
                                  <img src={video_stop} />
                                </div>
                            </button>
                            <button class="replay" onClick={handleRestartVideo}>
                                <div class="replay_img">
                                    <img src={video_replay} />
                                </div>
                            </button>

                        </div>
                        </div>
                        <div className="for-sp">
                        {/* mutedをつけないとスマホで自動再生できないため、やむなくdangerouslySetInnerHTMLを使用 */}
                            <div dangerouslySetInnerHTML={{__html: `<video src=${video_straumann} loop autoplay playsinline muted />`}} />
                        </div>

                    </div>

                    <div className="texts">
                        <div className="merit">
                            <h3>メリット</h3>
                            <ul>
                                <li>従来の矯正装置に比べ、透明なマウスピース型の装置を使用することで、口元の印象を変えることなく、目立たず矯正治療を行うことができる。</li>
                                <li>患者様ご自身で取り外しが可能なため、ブラッシングや食事などの日常生活に大きな影響を与えない。</li>
                                <li>薄いマウスピースで作られているため、違和感が少ない。</li>
                                <li>装置が邪魔をしないため、虫歯のリスクが少ない。</li>
                                <li>治療開始前にシミュレーションができる。</li>
                            </ul>
                        </div>

                        <div className="demerit">
                            <h3>デメリット</h3>
                            <ul>
                                <li>1日22時間以上装着する必要があるため、患者様ご自身の治療に対するモチベーションにより、治療の成功率が左右される。</li>
                                <li>外出先での食事の際には取り外す必要があり、食後はブラッシングして再装着する必要があるため、煩わしく感じることがある。</li>
                                <li>症例によって治療が長期間となることがある。</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">下記のような歯並びでお悩みの方は是非一度ご相談ください。</h2>
                </div>

                <div className="teethrow">
                    <img className="" src ={teethrow} />
                </div>

            </section>

            <Dentalpractice />
            
            <Specialtreatment />
            
            <DentalSchedule />
            
            <Access />
        </div>
    );
}

export default Correction ;