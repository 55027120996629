/**ホワイトニング */
import React, { useState , useRef } from 'react';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/dental/dental-periodontal.scss';
import triangleLine from '../icons/triangleline.svg';

function whitening (){
    return (
        <div>
            <ImageWithTitle title="ホワイトニング"/>

            <div className="main">
                <p>
                    歯の色は飲食物等の嗜好品や加齢、詰め物の影響など、様々な要因によって黄ばんできます。<br />
                    近年では、以前にも増して美容医療が注目されており、特殊な薬剤と光の化学反応によって歯に付着した色素を分解するホワイトニングも注目されています。<br />
                    専用の薬剤と光の効果で比較的短時間で歯を白くすることができ、人気を集めています。<br />
                    <br />
                    当院では歯科医療としてのホワイトニングを行なっており、事前に診査・診断を行い、より安全にホワイトニング治療を提供いたします。<br />
                    歯科医院以外で行われるホワイトニングサロンとの違いは使用薬剤に高濃度の過酸化水素や過酸化尿素が配合されており、<br />
                    歯を白くする効果が高いだけでなく、衛生士による事前のクリーニングも含まれるため、色素分解を阻害するプラークや歯石、<br />
                    軽度の着色をクリーニング専用の機械を用いた機械的歯面清掃を行うことで除去し、効率良く歯を白くすることができます。
                </p>

                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">審美治療における主な使用材料の種類</h2>
                </div>
                
                <div>
                    <h3>ホームホワイトニング</h3>
                    <p>
                        患者様ご自身で行なっていただくホワイトニングとなります。<br />
                        各個人のお口にあった専用のマウスピースを作成し、そこにホワイトニングジェルを入れて使用していただくことで、歯を白くしていきます。<br />
                        マウスピースを使用するため、前歯だけでなく奥歯にもホワイトニング効果が得られます。<br />
                        使用薬剤は低濃度のジェルを使用し、ゆっくり時間をかけて薬剤を浸透させていくため、ホワイトニング効果が長持ちします。<br />
                        その反面、効果が得られるまでに少し時間がかかるという特徴があります。<br />
                        お時間に余裕がある方にオススメです。
                    </p>
                </div>

                <div>
                    <h3>オフィスホワイトニング</h3>
                    <p>
                        院内で行うホワイトニングとなります。
                        専用の薬剤と光を用いて短時間で歯を白くすることができます。<br />
                        その反面、ホワイトニング効果が落ちやすい（後戻りしやすい）という特徴があります。<br />
                        また奥歯など一部適用できない部位もあります。<br />
                        結婚式等の撮影などで短時間で歯を白くしたい方にオススメです。
                    </p>
                </div>

                <div>
                    <h3>デュアルホワイトニング</h3>
                    <p>
                        ホームホワイトニングとオフィスホワイトニングの長所を生かしたホワイトニングとなります。<br />
                        短時間で歯を白くするオフィスホワイトニングと効果が長持ちするホームホワイトニングを併用することで、短時間で歯を白くし、長持ちさせることができます。<br />
                        口元の印象をガラッと変えて、長く効果を持続させたい方にオススメです。
                    </p>
                </div>

                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">ホワイトニング治療の流れ</h2>
                </div>

                <p>
                1.	カウンセリング<br />
                衛生士が患者様のご要望をお伺い致します。予算や期間などご質問にお答え致します。<br />
                <br />
                2.	事前診査<br />
                ホワイトニングを行うにあたり、虫歯や歯周炎、歯の亀裂、被せ物の有無や状態、使用薬剤に対するアレルギーの有無を確認致します。上記項目で問題がある場合は、事前に治療を行うなどの対処やホワイトニング中止となる場合があります。<br />
                <br />
                3.	口腔内写真撮影<br />
                ホワイトニング前後で色調の違いを確認していただくため、写真撮影を行います。ホームホワイトニングもオフィスホワイトニングも記録を残しておくことは非常に重要です。<br />
                <br />
                4.	ホワイトニング<br />
                事前のクリーニングを済ませた状態で、患者様のお口に合う器具を使用し専用の薬剤と光を用いてホワイトニングを行います。ホームホワイトニングの場合は専用のマウスピース作成のための型採りを行います。<br />
                <br />
                5.	クリーニング<br />
                薬剤の洗浄、知覚過敏予防のためフッ素塗布などを行います。<br />
                <br />
                6.	フォローアップ<br />
                今後の口腔内環境意地のための説明や、ホワイトニング後の写真撮影を行います。気になる点などあれば、ご質問にお答え致しますので遠慮なくご質問ください。
                
                </p>

                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">料金表</h2>
                </div>

                <p>
                    ホームホワイトニング 上下顎 32000円<br />
                    片顎 20000円<br />
                    追加ジェル(2週間分) 4400円<br />
                    <br />
                    オフィスホワイトニング 上下１回 16000円<br />
                    <br />
                    デュアルホワイトニング 上下１回 45000円<br />
                    <br />
                    ＊全てクリーニング代込み<br />
                    ＊税込価格<br />
                </p>

                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">ホワイトニングのリスク・注意事項</h2>
                </div>

                <p>
                    知覚過敏症状が出ることがあります<br />
                    先天性の歯の変色や薬剤性の変色には効果が表れにくいまたは効果が得られない場合があります<br />
                    色調に個人差があります(生まれ持った歯の色味による違い)<br />
                    患者様ご自身で薬剤を使用したりするため、使用方法や容量など自己判断が難しい場合があります<br />

                </p>
            </div>
        </div>
    );
}

export default whitening ;