/**特殊診療 */
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/dental/dental-general.scss';
import triangleLine from '../icons/triangleline.svg';
import Specialtreatment from '../components/specialtreatment.js';
import TreatmentSchedule from '../components/schedule.js';
import Access from '../components/access.js';
import '../../styles/dental/dental-specialize.scss';
import whiteningimg from '../img/ホワイトニング.JPG';


function Specialize (){

    return(
        <div className="specialize">
            <ImageWithTitle title="ホワイトニング"/>
            
            <div className="specialize-title">
                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">ホワイトニングとは</h2>
                </div>
                <p>薬剤を使って歯を白くする治療ですホワイトニングに
関する治療はすべて自費治療となります</p>
            </div>

            <div className="specialize-contents">
                <div className="img-wrapper">
                    <img src={whiteningimg}></img>
                </div>
                <p>
                    オフィスホワイトニング・ホームホワイトニング・デュアルホワイトニングの３種類を取り扱っています。<br />
                    ご自身の生活リズムや料金によって合ったものを選んでいただくことができます♪ <br />
                    すべてのホワイトニング治療前に、PMTC（歯面清掃）をさせていただきます。<br />
                    （PMTC料金はホワイトニング価格に込みのため別途で費用はかかりません）
                </p>
            </div>
            <div className="profit">
                <div className="">
                    <h3>メリット</h3>
                    <div className="image-wrapper">
                    </div>
                    <p>短期間でホワイトニングの効果が表れる結婚式などで少し急ぎで歯を白くしたい方におすすめです。</p>
                </div>
                <div className="">
                    <h3>デメリット</h3>
                    <div className="image-wrapper">
                    </div>
                    <p>１回ごとの施術時間が長い色の後戻りがしやすい歯がしみやすくなる適応できる範囲が前歯部位に限られます。</p>
                </div>
            </div>
            

            <Specialtreatment />

            <TreatmentSchedule />
            
            <Access />









        </div>

    );
}

export default Specialize;