import React, { useState , useRef } from 'react';
import img1 from '../img/medical-front.JPG';
import img2 from '../img/Facility-1.JPG';
import img3 from '../img/medical-hall.JPG';
import img4 from '../img/Facility-4.JPG';
import '../../styles/components/slider.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


function MedicalSlider() {
    const images = [
        img1,
        img2,
        img3,
        img4
    ];

    const [activeSlide, setActiveSlide] = useState(0);
    const sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    return (
        <div className="slider-container">
            <Slider ref={sliderRef} {...settings} afterChange={currentSlide => setActiveSlide(currentSlide)}>
                {images.map((img, idx) => (
                    <div key={idx}>
                        <img src={img} alt={`Slide ${idx}`} />
                    </div>
                ))}
            </Slider>
            <div className="slider-buttons">
                {images.map((_, index) => (
                    <button 
                        key={index}
                        onClick={() => sliderRef.current.slickGoTo(index)}  // sliderRefを使用してslickGoToメソッドを呼び出す
                        className={`slider-button ${activeSlide === index ? 'active' : ''}`}
                    >
                    </button>
                ))}
            </div>
        </div>
    );
}

export default MedicalSlider;