/**歯周病 */
import React, { useState , useRef } from 'react';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/dental/dental-periodontal.scss';

function periodontal (){
    return(
        <div>
            <ImageWithTitle title="予防歯科・歯周病"/>

            <div className="main">
                <h3 className='red'>
                    歯茎が腫れている ・歯茎から出血する
                </h3>

                <p>
                    歯茎の腫れやブラッシング時などに歯茎から血が出るのは、<span className='red'>歯肉炎・歯周炎</span>になっている可能性が考えられます。<br />
                    歯肉炎は、ブラッシング不足により溜まった歯垢（プラーク）や歯石の中で多くの細菌が繁殖し、歯茎が炎症を起こし腫れや出血といった症状が起こります<br />
                    歯肉炎のように、ただ腫れているだけなら入念なブラッシングで症状が改善することもありますが、歯垢（プラーク）や歯石を放置していると炎症によりやがて歯を支えている顎の骨が吸収され（吸収…歯を支える顎の骨が溶けてしまうこと）歯周炎となります<br />
                    歯周炎はひどくなるといずれ歯が抜け落ちてしまいます<br />
                    <br />
                    歯肉炎から歯周炎になってしまうとブラッシングだけで症状を改善することは難しく、これ以上悪くならないようにするための予防歯科治療が必要となります
                </p>
                <div className="textbox2">
                    <h3 className='red'>
                        予防歯科治療について
                    </h3>
                    
                    <p>
                        歯科医院で行う予防歯科治療は、歯茎の検査やブラッシングでは取り除くことのできない汚れを除去したり、汚れをつきにくくする為に歯の表面を磨いたりとご自身では難しい処置を行います。<br />
                        <br />
                        ①歯茎の検査をさせていただき、最初の歯茎の状態を把握します<br />
                        ↓<br />
                        ②超音波による振動で歯垢（プラーク）や歯石を取り除いたり、状態の悪い歯茎に対しては部分的に麻酔をさせていただき専用の器具を使い悪い汚れをかき出し、洗浄します<br />
                        状態に応じてこの処置は複数回に分けることがあります<br />
                        ↓<br />
                        ③歯の表面を専用ブラシや専用の歯磨き粉を使って、綺麗に磨いていきます<br />
                        ↓<br />
                        ④再度歯茎の検査をさせていただき、歯茎の状態の経過をみていきます<br />
                        <br />
                        これを定期的に繰り返していきます<br />
                        <br />
                        歯周病の治療はこれ以上悪くならないように「<span className='red'>予防する</span>」という治療になります<br />
                        一度悪くなってしまった歯茎の状態を元に戻すことは難しいです<br />
                        そうなってしまう前に、歯科医院での<span className='red'>定期的なメインテナンス</span>を行なっていくことが大切です<br />
                        定期的なメインテナンスは、自分では気づかなかった小さなむし歯やお口の中の小さな変化などの早期発見にもつながります

                    </p>
                </div>
            </div>
            
            </div>

    );
}

export default periodontal;