import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import triangleLine from '../icons/triangleline.svg';
import implant1 from '../img/implant1.png';
import implant2 from '../img/implant2.png';
import implant3 from '../img/implant3.png';
import Dentalpractice from '../components/dentalpractice';
import Specialtreatment from '../components/specialtreatment';
import DentalSchedule from '../components/dental-schedule';
import Access from '../components/access';

function DentalImplant() {
    
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="インプラントとは" />
                </div>
            </section>
            <section className="contents">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">インプラントとは</h2>
                    </div>
                <div className="container implantconteiner">
                    <div className="container-box">
                        <div className="container-box__text implanttext">
                        歯のない部分に人工歯根を埋めて<br /> 
                        天然歯に近い状態を再現する外科治療です
                        </div>
                        <div className="implant">
                            <div className="imgwrapper">
                                    <img src={implant1} />
                            </div>
                            <div className="imgwrapper">
                                    <img src={implant2} />
                            </div>
                            <div className="imgwrapper">
                                    <img src={implant3} />
                            </div>
                        </div>
                        <div className="container-box__text ">
                            当院ではインプラントによる治療を扱っております。<br />
                            大学病院で学んだ豊富な知識と技術を生かし、患者様に安心安全な治療をお届けします。<br />
                            事前説明はもちろんアフターケアまでお任せください。<br />
                            当院では「Straumann（ストローマン）」のインプラント材料を使用しています。<br />
                            インプラント治療はすべて自費治療となります。
                        </div>                     
                    </div>
                </div>

            </section>

            <Dentalpractice />
            
            <Specialtreatment />
            
            <DentalSchedule />
            
            <Access />
        
        </div>
    )
}

export default DentalImplant;