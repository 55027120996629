import React, { useState } from 'react';
import ImageWithTitle from '../components/imagewithtitle';
import DentalButton from '../components/dentalbuttton';
import '../../styles/dental/dental-firstimpression.scss';


function DentalFirstImpression(){

    return(
        <div className="firstimpression">
            <section className="top">
                <div>
                    <ImageWithTitle title="当院が初めての方へ" />
                </div>
            </section>
            <div className="container-box__text">

                <h3>１、予約をとっていただきます</h3>
                <p>基本的には予約制のため、予約をお取りいただきます</p>
                <p className="red">新患・急患の方随時受け付けております♪</p>
                <p>診察内容などによりお時間お待ちいただく場合や順番が前後する場合もありますのでご了承ください</p>

                <div>
                    <p>歯科専用窓口 <span className="red">027−395−6677</span></p>
                    <div className="button_wrapper">
                        <DentalButton className="image-links__button" to="https://epark.jp/shopinfo/shk402608/">ネットで予約</DentalButton>
                        <DentalButton className="image-links__button" to="https://lin.ee/2DYRtiD">ラインで予約</DentalButton>
                    </div>
                </div>

                <h3>２、問診票の記入</h3>
                <p>問診票を記入していただきます<br />
                    できるだけ詳しくご記入いただけると嬉しいです♪<br />
                    また、保険証のコピーと現在お薬服用中の方はお薬手帳のコピーもとらせていただきます<br />
                    お薬手帳を持っていない場合はお薬の名前や種類のわかるものをお持ちください
                </p>

                <h3>３、口腔内の確認と説明</h3>
                <p>診療室に入っていただき、口腔内の状況を確認させていただきます<br />
                    現在のお口の中の記録をとらせていただき、主な症状（痛みのある部位や現在一番困っていること）等をお聞きします<br />
                    <br />
                    必要があれば、レントゲン写真撮影や歯周組織検査（歯ぐきの検査）等をさせていただき、より詳しい確認をさせていただくことがあります<br />
                    （レントゲン撮影等、心配な方はご相談ください）<br />
                    <br />
                    データを元に患者様の現在のお口の中の状況や今後の治療方針の説明をさせていただきます<br />
                    ご不明な点や確認・要望等あればいつでもお声がけください♪
                </p>

                <h3>４、治療開始</h3>
                <p>十分な説明をさせていただき、患者さまにご納得いただいた上で治療開始となります<br />
                <br />
                ※安全な治療を行っていく上で、患者様の要望にお答えできない場合もあります、その場合はしっかりと説明をさせていただいた上で治療をさせていただきますのでご了承ください
                </p>
            </div>

        </div>

    );

}

export default DentalFirstImpression;