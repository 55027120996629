import React, { useState } from 'react';
import '../../styles/medical/medical-surgery.scss';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/informationsite/vaccination240401.scss';

const Vaccination240401 = () => {
    
    return (
        <div className="informationsites">
                <ImageWithTitle title="帯状疱疹予防接種の公費助成について"/>

                <div className="main">
                    <section className="01">
                        <div>
                            <h3>※公費助成は<span className="red">令和6年4月1日以降</span>の接種に限ります。<span className="red">それ以前</span>の接種について、償還払い等の対応はありません。</h3>
                        </div>
                    </section>

                    <section className="02">
                        <div>
                            <h3>※ 使用ワクチンは以下の2つです。</h3>
                            <p>①乾燥弱毒生水痘ワクチン(ビケン)・・・1回接種</p>
                            <p>②乾燥組換え帯状疱疹ワクチン(シングリックス筋注用)・・・2回接種</p>
                            
                            <div className="box">
                                <p>
                                    2回目は通常1回目終了後2か月後に接種します。2か月を過ぎてしまった場合でも、6か月を過ぎることはできません。<br />
                                    <span className="red">6か月を過ぎる</span>と、公費助成の対象外となります。<br />
                                    <br />
                                    “6か月を過ぎる”の例:<span className="red">1回目が5月31日の場合</span>、6か月後は12月1日となり、この日に6か月を過ぎるため、2回目の接種は、<span className="red">前日の11月30日</span>までに受けてください。<br />
                                    (6か月後”とは、1回目(=5月31日)から6か月後の同じ日(=11月31日)ですが、該当の日が存在しない場合は翌日(= 12月1日)となります。)
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className="03">
                        <div>
                            <h3>
                                公費助成は、<span className="red">接種当日に高崎市に住民登録</span>がある、<span className="red">50歳以上(※下記参照)</span>の市民が対象です。<br />
                                接種当日に絡む転出入等が無いかどうか、確認をお願いします。
                            </h3>
                            <p>
                                ※乾燥組換え帯状疱疹ワクチン(シングリックス筋注用)については、『帯状疱疹に罹患するリスクが高いと考えられる満18歳以上の者』(疾病又は治療により免疫不全である者、免疫機能が低下した者又は免疫機能が低下する可能性がある者、その他医師が接種を必要と認めた者)も対象となります。
                            </p>
                        </div>
                    </section>

                    <section className="04">
                        <div>
                            <h3>助成額については以下のとおりです。</h3>
                            <p>①乾燥弱毒生水痘ワクチン(ビケン)は接種料金から上限4,000円を市が負担。</p>
                            <p>②乾燥組換え帯状疱疹ワクチン(シングリックス筋用)は、接種料金から1回につき上限10,000円(2回で最大20,000円)を市が負担。</p>
                        </div>
                    </section>

                    <section className="05">
                        <div>
                            <h3>接種希望の方は電話で予約をお願い致します。</h3>
                        </div>
                    </section>
                </div>

        </div>


    )
}

export default Vaccination240401;