import React from "react";
import '../../styles/dental/dental-top.scss';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import '../../styles/components/symptoms.scss';

// アイコンインポート
import image1 from '../icons/歯が痛い、しみる.svg';
import image1hover from '../icons/歯が痛い、しみるホバー.svg';
import image2 from '../icons/噛むと痛い.svg';
import image2hover from '../icons/噛むと痛いホバー.svg';
import image3 from '../icons/顎が痛い.svg';
import image3hover from '../icons/顎が痛いホバー.svg';
import image4 from '../icons/入れ歯を入れたい.svg';
import image4hover from '../icons/入れ歯を入れたいホバー.svg';
import image5 from '../icons/歯が揺れている.svg';
import image5hover from '../icons/歯が揺れているホバー.svg';
import image6 from '../icons/舌や頬に痺れ.svg';
import image6hover from '../icons/舌や頬に痺れホバー.svg';
import image7 from '../icons/歯が欠けた.svg';
import image7hover from '../icons/歯が欠けたホバー.svg';
import image8 from '../icons/歯の色が気になる.svg';
import image8hover from '../icons/歯の色が気になるホバー.svg';
import image9 from '../icons/歯茎から出血.svg';
import image9hover from '../icons/歯茎から出血ホバー.svg';
import image10 from '../icons/口臭が気になる.svg';
import image10hover from '../icons/口臭が気になるホバー.svg';
import image11 from '../icons/詰め物が取れた.svg';
import image11hover from '../icons/詰め物が取れたホバー.svg';
import image12 from '../icons/その他.svg';
import image12hover from '../icons/その他ホバー.svg';



const Symptom = () => {

    return(
            <section className="departments">
                <h2 className="title__text">症状別で探す</h2>
                <div className="departments__buttonbox">
                <ButtonSubject 
                    to="/dental-symptoms1" 
                    imgSrc={image1}
                    hoverImgSrc={image1hover}
                >
                    歯が痛い<br />しみる
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms2" 
                    imgSrc={image2}
                    hoverImgSrc={image2hover}
                >
                    噛むと痛い
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms3" 
                    imgSrc={image3}
                    hoverImgSrc={image3hover}
                >
                    口が開かない<br />顎が痛い
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms4" 
                    imgSrc={image4}
                    hoverImgSrc={image4hover}
                >
                    入れ歯を作りたい
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms5" 
                    imgSrc={image5}
                    hoverImgSrc={image5hover}
                >
                    歯が揺れている
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms6" 
                    imgSrc={image6}
                    hoverImgSrc={image6hover}
                >
                    舌や頬に出来物や痺れ
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms7" 
                    imgSrc={image7}
                    hoverImgSrc={image7hover}
                >
                    歯が欠けた<br />穴があいた
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms8" 
                    imgSrc={image8}
                    hoverImgSrc={image8hover}
                >
                    歯の色が気になる
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms9" 
                    imgSrc={image9}
                    hoverImgSrc={image9hover}
                >
                    歯茎から出血や膿
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms10" 
                    imgSrc={image10}
                    hoverImgSrc={image10hover}
                >
                    口臭が気になる
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms11" 
                    imgSrc={image11}
                    hoverImgSrc={image11hover}
                >
                    詰め物・被せ物が取れた
                </ButtonSubject>
                <ButtonSubject 
                    to="/dental-symptoms12" 
                    imgSrc={image12}
                    hoverImgSrc={image12hover}
                >
                    その他
                </ButtonSubject>
                </div>
                <div className="departments__img">
                <img src={triangleMatrix} className="departments__img--topleft" />
                <img src={triangleMatrix} className="departments__img--bottomright" />
                </div>
            </section>
    )
};

export default Symptom;