import React, { useState } from 'react';
import '../../styles/components/medicalinformation.scss';
import triangleLine from '../icons/triangleline.svg';
import Button from './externallink';
import { Link } from 'react-router-dom';

const MedicalInjection = () => {

    return(
        <div className="medicalinformation">
        <section className="title">
            <div className="title">
                <img className="title__picture" src={triangleLine} alt="" />
                <h2 className="title__text">予防接種について</h2>
            </div>
        </section>

        <section className="column">
                <h3>予防接種について</h3>
                <p>当院で実施している予防接種は季節性インフルエンザ以外は要予約となっております。</p>
                <p>【予防接種一覧】<br />
                    ・麻疹<br />
                    ・麻疹＋風疹混合<br />
                    ・水痘<br />
                    ・B型肝炎<br />
                    ・破傷風<br />
                    ・肺炎球菌<br />
                    ・帯状疱疹<br />
                    　<Link  className="underbar" to="/information-01">※帯状疱疹予防接種の公費助成について(概要 まとめ)</Link><br />
                    ・ムンプス<br />
                    ・子宮頸がん<br />
                    ・新型コロナ<br />
                    ・季節性インフルエンザ<br />
                    （毎年10月より随時実施しているので予約の必要はありません。）
                </p>
        </section>
    </div>
    );

}

export default MedicalInjection;