import React from 'react';
import TreatmentSchedule from '../components/schedule';
import MedicalInformation from '../components/medicalinformation';
import ImageWithTitle from '../components/imagewithtitle';
import imgSurgery from '../img/メンテ中.jpeg';
import '../../styles/medical/medical-surgery.scss';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import ButtonBox from '../components/buttonbox';

function industrialphysician () {
    return(
        <div className="medicalsurgery">
            <ImageWithTitle title="産業医"/>

            <section className="main">
                <div className="box">
                    <div className="img">
                        <img src={imgSurgery}></img>
                    </div>
                    <p>産業医とは、事業場において労働者が健康で快適な作業環境のもとで仕事が行えるよう、専門的立場から指導・助言を行う医師を云います。<br />
                    産業医学の実践者として産業保健の理念や労働衛生に関する専門的知識に精通し労働者の健康障害を予防するのみならず、心身の健康を保持増進することを目指した活動を遂行する任務があります。<br />
                    <br />
                    詳しくは<a className="red" href="https://www.mhlw.go.jp/index.html">厚生労働省</a>のページをご覧ください 
                    </p>
                </div>
            </section>

            {/* 3. 科目別で探すセクション */}
            <section className="medical-top__departments">
                <h2 className="title__text">科目別で探す</h2>
                <ButtonBox />
                <div className="medical-top__img">
                <img src={triangleMatrix} className="medical-top__img--topleft" />
                <img src={triangleMatrix} className="medical-top__img--bottomright" />
                </div>
            </section>

            <TreatmentSchedule />

            <MedicalInformation />
        </div>

    );

}

export default industrialphysician;