/**便宜抜歯 */
import React, { useState , useRef } from 'react';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/dental/dental-periodontal.scss';


function relapse (){
    return (
        <div>
            <ImageWithTitle title="便宜抜歯"/>

            <div className="main">
                <h3 className="red">
                    矯正治療に必要な抜歯について
                </h3>

                <p>
                    矯正治療のための抜歯の依頼、承っております<br />
                    <br />
                    矯正治療に必要な抜歯に関しまして<br />
                    矯正歯科からのお手紙を必ずお持ちください<br />
                    <br />
                    矯正治療のための抜歯は、自費治療となります<br />
                    １歯　￥5000+税　（処置代等込）<br />
                    ２歯同時処置　￥8000+税　（処置代等込）<br />
                    医師の判断により２歯同時処置は、行わない場合もありますのでご了承ください<br />
                    <br />
                    <span className="red">抜歯を行う際はトラブル防止のため事前に「抜歯に関する同意書」にサインをいただいております</span>

                </p>
            </div>
        </div>
    );
}

export default relapse ;