// 詰め物・被せ物が取れた
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms11() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="詰め物・被せ物が取れた" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            白いプラスチック製の詰め物や銀歯等の被せ物が取れてしまう原因は、虫歯や食物、噛み合わせによるものが考えられます。<br />
                            詰め物や被せ物は歯科用の接着剤を用いて装着しています。<br />
                            粘りの強い物（ガムやお餅、飴）を噛むと取れしまうことがあります。<br />
                            また虫歯になると接着剤の接着力が低下します。<br />
                            いずれの場合も放置すると虫歯の進行や、噛み合わせの偏位を助長する恐れがあるため、早めに受診することをお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />
        </div>
    );


};

export default Dentalsymptoms11;