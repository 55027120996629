// 歯が欠けた　穴があいた
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms7() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="歯が欠けた・穴があいた" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            歯が欠けた、穴が空いている原因として、虫歯や口腔悪習癖（歯軋りなど）が挙げられます。<br />
                            穴が空いている歯を放置すると虫歯は進行します。<br />
                            また欠けた歯を放置すると舌や頬を傷つけて潰瘍形成を助長したり、虫歯の原因にもなります。<br />
                            対応として虫歯の治療や噛み合わせの調整、被せ物の製作等を行います。<br />
                            上記の症状が出ている場合は早めに受診することをお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />

        </div>
    );


};

export default Dentalsymptoms7;