import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';


const ButtonSubject = ({
    to,
    children,
    color = '#FFF',
    padding = '40px 0',
    imgSrc,
    hoverImgSrc,
    imgPadding = '0'  // 画像のパディングを調整するための新しいプロップ
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleClick = (event) => {
      event.preventDefault();
      navigate(to);
      window.scrollTo(0, 0); // ページ遷移時に最上部にスクロール
    };

    const buttonStyle = {
      background: color,
      padding: padding,
      borderRadius: '20px',
      textAlign: 'center',
      border: '1px solid #000',
      display:'flex',
      flexDirection: 'column',
      alignItems:'center',
      width: '100%',
      maxWidth:'200px',
      transition: 'background 1s, border 1s',
    };

    const imgContainerStyle = {
        display: 'flex',
        padding: imgPadding,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: imgPadding,
    };

    const imgStyle = {
        width: 'auto',
        transition: 'opacity 1s, display 1s',
        display: isHovered ? 'none' : 'block',
      };

    const textStyle = {
        color:'#000',
        maxWidth:150,
      };

    const hoverButtonStyle = {
        ...buttonStyle,  // 元のスタイルを継承
        background: isHovered ? '#76CED5' : color,
        border: isHovered ? '1px solid white' : '1px solid #000',
      };

    const hoverImgStyle = {
        ...imgStyle,
        opacity: isHovered ? 1 : 0,  // ホバー時に1、ホバー外れた時に0に設定
        display: isHovered ? 'block' : 'none',
      };

    const hoverTextStyle = {
        ...textStyle,
        color: isHovered ? '#fff' : '#000',
      };
    




    return (
      <Link 
        to={to} 
        style={hoverButtonStyle}
        onMouseEnter={() => setIsHovered(true)}  // ホバー開始時に isHovered を true に設定
        onMouseLeave={() => setIsHovered(false)} // ホバー終了時に isHovered を false に設定
        >
        <div style={imgContainerStyle}>
            <img style={imgStyle} src={imgSrc} alt="Button subject" />
            {hoverImgSrc && (
            <img style={hoverImgStyle} src={hoverImgSrc} alt="Hover image" />
            )}
        </div>
        <p 
            style={hoverTextStyle}
            onMouseEnter={() => setIsHovered(true)}  // ホバー開始時に isHovered を true に設定
            onMouseLeave={() => setIsHovered(false)} // ホバー終了時に isHovered を false に設定
        >
            {children}
        </p>
      </Link>
    );
  }

export default ButtonSubject;