import React, { useState , useRef, useEffect  } from 'react';
import dentalFront from '../img/受付.JPG';
import dentalHall from '../img/外観とエントランス.JPG';
import exterior1 from '../img/exterior-1.JPG';
import medicalFront from '../img/medical-front.JPG';
import '../../styles/components/slider.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


function ImageSlider() {
    const images = [
        exterior1,
        dentalHall,
        medicalFront,
        dentalFront
    ];

    const [activeSlide, setActiveSlide] = useState(0);
    const sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    useEffect(() => {
        console.log("useEffect is running");
        // 画像の事前読み込み
        images.forEach((img) => {
            const image = new Image();
            image.src = img;
        });
    }, []);

    return (
        <div className="slider-container">
            <Slider ref={sliderRef} {...settings} afterChange={currentSlide => setActiveSlide(currentSlide)}>
                {images.map((img, idx) => (
                    <div key={idx}>
                        <img src={img} alt={`Slide ${idx}`} />
                    </div>
                ))}
            </Slider>
            <div className="slider-buttons">
                {images.map((_, index) => (
                    <button 
                        key={index}
                        onClick={() => sliderRef.current.slickGoTo(index)}  // sliderRefを使用してslickGoToメソッドを呼び出す
                        className={`slider-button ${activeSlide === index ? 'active' : ''}`}
                    >
                    </button>
                ))}
            </div>
        </div>
    );
}

export default ImageSlider;
