/**特殊診療 */

import React, { useState } from 'react';
import triangleLine from '../icons/triangleline.svg';
import '../../styles/components/specialtreatment.scss';
import DentalButton from './dentalbuttton';
import detal1 from '../img/矯正.jpeg';
import detal2 from '../img/ホワイトニング.jpeg';
import detal3 from '../img/審美歯科.webp';
import detal4 from '../img/レーザー治療.jpeg';
import implant from '../img/implant3.png';

const specialtreatment = () => {
    
    return (
        <section className="specialtreatment">
            <div className="specialtreatment__box">
                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">自費/特殊診療</h2>
                </div>
                <div className="specialtreatment__box__list">
                    <a href="/dental-correction">
                        <div className="specialtreatment__box__list__wrapper">
                            <div className="specialtreatment__box__list__wrapper__textbox">
                                <p>マウスピース矯正</p>
                            </div>
                            <div className="specialtreatment__box__list__wrapper__imgwrapper">
                                <img src={detal1}></img>
                            </div>
                            
                        </div>
                    </a>
                    <a href="/dental-whitening">
                        <div className="specialtreatment__box__list__wrapper">
                            <div className="specialtreatment__box__list__wrapper__textbox">
                                <p>ホワイトニング</p>
                            </div>
                            <div className="specialtreatment__box__list__wrapper__imgwrapper">
                                <img src={detal2}></img>
                            </div>
                            
                        </div>
                    </a>
                    <a href="/dental-aesthetic">
                        <div className="specialtreatment__box__list__wrapper">
                            <div className="specialtreatment__box__list__wrapper__textbox">
                                <p>審美治療</p>
                            </div>
                            <div className="specialtreatment__box__list__wrapper__imgwrapper">
                                <img src={detal3}></img>
                            </div>
                            
                        </div>
                    </a>
                    <a>
                    </a>
                    <a href="/dental-implant">
                        <div className="specialtreatment__box__list__wrapper">
                            <div className="specialtreatment__box__list__wrapper__textbox">
                                <p>インプラント</p>
                            </div>
                            <div className="specialtreatment__box__list__wrapper__imgwrapper">
                                <img src={implant}></img>
                            </div>
                            
                        </div>
                    </a>
                </div>
            </div>
        </section>






    )

};

export default specialtreatment;