/**乳腺・甲状腺専門外来 */
import React from 'react';
import TreatmentSchedule from '../components/schedule';
import MedicalInformation from '../components/medicalinformation';
import ImageWithTitle from '../components/imagewithtitle';
import img from '../img/メンテ中.jpeg';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import '../../styles/medical/medical-surgery.scss';
import ButtonBox from '../components/buttonbox';

function MedicalSpecialized (){

    return(
        <div className="medicalsurgery">
        <ImageWithTitle title="乳腺、甲状腺専門外来"/>

        <section className="main">
            <div className="box">
                <div className="img">
                    <img src={img}></img>
                </div>
                <p>乳房周辺のシコリなどの異常（乳がんや良性のシコリ）<br />
                くびのしこり、首が太いなどは甲状腺の病気（橋本病、バセドウ病など）などがあります
                </p>
            </div>
        </section>

        {/* 3. 科目別で探すセクション */}
        <section className="medical-top__departments">
            <h2 className="title__text">科目別で探す</h2>
            <ButtonBox />
            <div className="medical-top__img">
            <img src={triangleMatrix} className="medical-top__img--topleft" />
            <img src={triangleMatrix} className="medical-top__img--bottomright" />
            </div>
        </section>

        <TreatmentSchedule />

        <MedicalInformation />
    </div>
    );
}

export default MedicalSpecialized;