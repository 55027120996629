import React, { useState } from 'react';
import '../../styles/components/schedule.scss';
import triangleLine from '../icons/triangleline.svg';

const DentalSchedule = () => {



    return (
        <section className="treatmentschedule">
            <div className="main">
                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">診療予定</h2>
                </div>

                <div className="schedule">
                    <h2 className="schedule__title">
                        診療予定表
                    </h2>
                    <div className="schedule__contents">
                        <div className="schedule__contents__textbox">
                            <h4 className="schedule__contents__textbox__caption">
                                当院は、原則お電話による<span className="red">「予約制」</span>です。
                            </h4>
                            <p>
                                ただし緊急時や重症患者様の診療・治療のため予約時間どおりとはならない場合がございますのであらかじめご了承願います。
                                <br />詳しい状況につきましては当日受付にお問合せください。
                            </p>
                        </div>
                        <div className="schedule__contents__time">
                            <p className="schedule__contents__time__title">
                                電話受付時間
                            </p>
                            <div className="schedule__contents__time__wrapper">
                                <div className="schedule__contents__time__wrapper__subject">
                                    <p>
                                        受付時間
                                    </p>
                                </div>
                                <div className="schedule__contents__time__wrapper__contents"> 
                                    <p>
                                        9:00 〜 12:30&nbsp;/&nbsp;14:00 〜 18:30
                                    </p>                                 
                                </div>
                            </div>
                        </div>
                        <div className="schedule__contents__time">
                            <p className="schedule__contents__time__title">
                                窓口受付時間
                            </p>
                            <div className="schedule__contents__time__wrapper">
                                <div className="schedule__contents__time__wrapper__subject">

                                    <p>
                                        受付時間
                                    </p>

                                </div>

                                <div className="schedule__contents__time__wrapper__contents"> 

                                    <p>
                                        9:00 〜 12:00&nbsp;/&nbsp;14:00 〜 18:00
                                    </p>
                                    
                                </div>

                            </div>
                        </div>
                        <div className="schedule__contents__treatmentschedule">
                            <p className="schedule__contents__time__title">
                                診療予定表
                            </p>
                            
                            <div className="table-container">
                                <div className="table-row">
                                    <div className="table-header"></div>
                                    <div className="table-header">月</div>
                                    <div className="table-header">火</div>
                                    <div className="table-header">水</div>
                                    <div className="table-header">木</div>
                                    <div className="table-header">金</div>
                                    <div className="table-header">土</div>
                                    <div className="table-header">日</div>
                                    <div className="table-header">祝</div>
                                </div>
                                <div className="table-row">
                                    <div className="table-cell">
                                    9:00
                                    -
                                    12:30</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell red">休</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell red">休</div>
                                    <div className="table-cell red">休</div>
                                </div>
                                <div className="table-row">
                                    <div className="table-cell">
                                    14:00
                                    -
                                    19:30</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell red">休</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell">○</div>
                                    <div className="table-cell red">休</div>
                                    <div className="table-cell red">休</div>
                                </div>







                            </div>

                        </div>
                    </div>
                </div>

                <div className="closedday">
                    <h2 className="closedday__title">
                        休診日
                    </h2>
                    <h3 className="closedday__title--sub">
                        毎週木,日,祝
                    </h3>
                    <p className="closedday__text">
                        都合により上記以外で休診とさせていただく場合もございますので、<br />
                        詳しくは受付にお問い合わせください。
                    </p>

                </div>

            </div>



        </section>


    );
};

export default DentalSchedule;