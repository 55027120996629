/**レーザー治療 */
import React, { useState , useRef } from 'react';
import ImageWithTitle from '../components/imagewithtitle';

function lasertreatment (){
    return (
        <div>
            <ImageWithTitle title="レーザー治療"/>
        </div>
    );
}

export default lasertreatment ;