/**求人ページ */
import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/dental/dental-recruit.scss';
import Keyvisual from '../img/keyvisual.JPG';
import triangleLine from '../icons/triangleline.svg';
import recruitvisual from '../img/recruit1.JPG';
import Button from '../components/button';




function DentalRecruit() {

    return(
        <div className="recruit">
            <section className="keyvisual">
                <div className="animation">
                    <img 
                        className="animation__image"
                        alt="Background"
                        src={Keyvisual} 
                    />
                </div>
            </section>

            <section className="information">
                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">採用情報</h2>
                </div>

                <div className="information__container">
                    <div className="information__container__img">
                        <img src={recruitvisual}>
                        </img>
                    </div>

                    <div className="information__container__text">
                        <p>当院では担当制を設けております</p>
                        <p>焦らずじっくり経験を積みたい人、</p>
                        <p>仕事を覚えたい人</p>
                        <p>一緒に働けるのを楽しみにしております</p>
                    </div>

                </div>

            </section>

            <section className="requirements">
                <div className="requirements__box">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">募集要項</h2>
                    </div>
                    <div className="requirements__box--white">
                        <div className="table">
                            <div className="table-row"> 
                                <div className="table-header">給与</div>
                                <div className="table-cell">スキル見合い</div> 
                            </div>
                            <div className="table-row"> 
                                <div className="table-header">勤務時間</div>
                                <div className="table-cell">8:30〜18:30</div> 
                            </div>
                            <div className="table-row"> 
                                <div className="table-header">募集要項</div>
                                <div className="table-cell">正社員:歯科衛生士・歯科助手<br />
                                パート：歯科衛生士・歯科助手</div> 
                            </div>
                            <div className="table-row"> 
                                <div className="table-header">条件</div>
                                <div className="table-cell">未経験歓迎<br />年齢不問<br />詳細は担当者に問い合わせ</div> 
                            </div>

                        </div>
                    </div>
                    <Button to="/recruitform">採用フォームはこちら</Button>

                </div>
                
            </section>




        </div>


    );




}

export default DentalRecruit;