// 歯が揺れている
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms5() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="歯が揺れている" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            ブラッシング時や食事をした際に歯茎から出血していたり、歯茎の違和感、歯の揺れ等を感じる原因として、歯肉炎・歯周炎が考えられます。<br />
                            歯肉炎とは、磨き残しや歯石の付着により歯茎が腫れてしまい、出血しやすい状態です。<br />
                            歯肉炎状態であれば歯石の除去とブラッシングで症状は軽快しますが、歯肉炎が進行し、歯周炎となってしまった場合は歯周炎治療が必要になります。<br />
                            歯周炎は、歯肉炎が進行し、歯を支える顎骨の吸収が始まっている状態です。<br />
                            症状の進行具合に関わらず、日本人の9000万人が罹患しているとも言われています。<br />
                            歯周炎の怖いところは、違和感や痛みといった症状が現れている時期（急性期）と何の症状もなくなってしまう時期（休止期）が交互に現れ、階段を上るように悪化していき、気づいたら歯が揺れていて抜歯になってしまうというケースも少なくないという点です。<br />
                            症状の進行度によりますが、定期検診にて予防することが可能です。<br />
                            上記症状が出ているようであれば、早めの受診をお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />
        </div>
    );


};

export default Dentalsymptoms5;