import React, { useState } from 'react';
import triangleLine from '../icons/triangleline.svg';
import '../../styles/components/dentalpractice.scss';
import DentalButton from './dentalbuttton';
import brush from '../img/toothbrush.jpeg';
import mouth from '../img/矯正歯科.jpeg';
import child from '../img/child.jpeg';
import mouthdental from '../img/口腔外科イメージ.jpg';


const Dentalpractice = () => {
    return(
        <section className="dentalpractice">
            <div className="for-pc">
                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">診療科目</h2>
                </div>
                <div className="flexwrapper">
                    <div className="flexwrapper__contents">
                        <div className="imgwrapper">
                            <img src={brush}></img>
                        </div>
                        <div className="text-box">
                            <h2>一般歯科</h2>
                            <p>虫歯の治療、根管治療、被せ物の治療、歯周病の予防処置、歯のクリーニング、歯ブラシの指導、定期的な健診など行っております
                                <br />
                                「自分ではわからない」また「判断の難しい」お口の中の治療、ご相談はお任せください
                            </p>
                            <div className="buttons">
                                <DentalButton to="/dental-general" className="buttons--three">虫歯治療</DentalButton>
                                <DentalButton to="/dental-symptoms13" className="buttons--three">根管治療</DentalButton>
                                <DentalButton to="/dental-periodontal" className="buttons--three">予防歯科<br />歯周病</DentalButton>

                            </div>
                        </div>
                    </div>
                    <div className="flexwrapper__contents">
                        <div className="text-box">
                            <h2>歯科口腔外科</h2>
                            <p>
                                当院では重度の虫歯や歯周炎により、保存困難となった歯の抜歯や親知らずの抜歯を行なっております。
                                <br />
                                その他、インプラントや歯根端切除術、歯肉形成等の観血的処置が必要な外科治療も行なっております。
                                <br />
                                難症例の親知らず抜歯に関しては、必要に応じて大学病院への紹介状をお書きすることもあります。
                                <br />
                                外科治療を行う際はトラブル防止のため、事前に十分な説明をさせて頂いたのち、治療同意書へのサインを頂いております。
                            </p>
                            <div className="buttons">
                                {/* <DentalButton to="/dental-general" className="buttons--three">普通抜歯</DentalButton>
                                <DentalButton to="" className="buttons--three">親知らずの抜歯</DentalButton> */}

                            </div>
                        </div>
                        <div className="imgwrapper">
                            <img src={mouthdental}></img>
                        </div>
                    </div>
                    <div className="flexwrapper__contents">
                        <div className="imgwrapper">
                            <img src={child}></img>
                        </div>
                        <div className="text-box">
                            <h2>小児歯科</h2>
                            <p>お子様の治療、歯科健診も行っています
                                <br />
                            保護者の方とお子様が一緒にお入り頂ける個室の診察室もありますので、安心して治療を受けていただくことができます
                            <br />
                            バリアフリーとなっているためベビーカーのお子様も一緒に入っていただけます
                            <br />
                            高崎市の２歳児個別歯科健診も実施しております
                            </p>
                        </div>
                    </div>
                    <div className="flexwrapper__contents">
                        <div className="text-box">
                            <h2>矯正歯科</h2>
                            <p>
                                治療装置の目立たないマウスピースを用いた矯正治療を行なっております。
                                <br />
                                審査は無料で行なっておりますので、ご興味のある方はご相談ください。
                                <br />
                                矯正治療のための便宜抜歯依頼、マウスピース矯正、承っております。詳しくは下記をご参照ください。
                            </p>
                            <div className="buttons">
                                <DentalButton to="/dental-relapse" className="buttons--three">便宜抜歯</DentalButton>
                                <DentalButton to="/dental-correction" className="buttons--three">マウスピース矯正</DentalButton>

                            </div>
                        </div>
                        <div className="imgwrapper">
                            <img src={mouth}></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="for-sp">
                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">診療科目</h2>
                </div>
                <div className="flexwrapper">
                        <div className="flexwrapper__contents">
                            <div className="imgwrapper">
                                <img src={brush}></img>
                            </div>
                            <div className="text-box">
                                <h2>一般歯科</h2>
                                <p>虫歯の治療、根管治療、被せ物の治療、歯周病の予防処置、歯のクリーニング、歯ブラシの指導、定期的な健診など行っております
                                    <br />
                                    「自分ではわからない」また「判断の難しい」お口の中の治療、ご相談はお任せください
                                </p>
                                <div className="buttons">
                                    <DentalButton to="/dental-general" className="buttons--three">虫歯治療</DentalButton>
                                    <DentalButton to="/dental-symptoms13" className="buttons--three">根管治療</DentalButton>
                                    <DentalButton to="/dental-periodontal" className="buttons--three">予防歯科<br />歯周病</DentalButton>

                                </div>
                            </div>
                        </div>
                        <div className="flexwrapper__contents">
                            <div className="imgwrapper">
                                <img src={mouthdental}></img>
                            </div>
                            <div className="text-box">
                                <h2>歯科口腔外科</h2>
                                <p>
                                    当院では重度の虫歯や歯周炎により、保存困難となった歯の抜歯や親知らずの抜歯を行なっております。
                                    <br />
                                    その他、インプラントや歯根端切除術、歯肉形成等の観血的処置が必要な外科治療も行なっております。
                                    <br />
                                    難症例の親知らず抜歯に関しては、必要に応じて大学病院への紹介状をお書きすることもあります。
                                    <br />
                                    外科治療を行う際はトラブル防止のため、事前に十分な説明をさせて頂いたのち、治療同意書へのサインを頂いております。
                                </p>
                                <div className="buttons">
                                    {/* <DentalButton to="/dental-general" className="buttons--three">普通抜歯</DentalButton>
                                    <DentalButton to="" className="buttons--three">親知らずの抜歯</DentalButton> */}

                                </div>
                            </div>
                        </div>
                        <div className="flexwrapper__contents">
                            <div className="imgwrapper">
                                <img src={child}></img>
                            </div>
                            <div className="text-box">
                                <h2>小児歯科</h2>
                                <p>お子様の治療、歯科健診も行っています
                                    <br />
                                保護者の方とお子様が一緒にお入り頂ける個室の診察室もありますので、安心して治療を受けていただくことができます
                                <br />
                                バリアフリーとなっているためベビーカーのお子様も一緒に入っていただけます
                                <br />
                                高崎市の２歳児個別歯科健診も実施しております
                                </p>
                            </div>
                        </div>
                        <div className="flexwrapper__contents">
                            <div className="imgwrapper">
                                <img src={mouth}></img>
                            </div>
                            <div className="text-box">
                                <h2>矯正歯科</h2>
                                <p>
                                    治療装置の目立たないマウスピースを用いた矯正治療を行なっております。
                                    <br />
                                    審査は無料で行なっておりますので、ご興味のある方はご相談ください。
                                    <br />
                                    矯正治療のための便宜抜歯依頼、マウスピース矯正、承っております。詳しくは下記をご参照ください。
                                </p>
                                <div className="buttons">
                                    <DentalButton to="/dental-relapse" className="buttons--three">便宜抜歯</DentalButton>
                                    <DentalButton to="/dental-correction" className="buttons--three">マウスピース矯正</DentalButton>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>



    );
};

export default Dentalpractice;