/**訪問診療 */
import React from 'react';
import TreatmentSchedule from '../components/schedule';
import MedicalInformation from '../components/medicalinformation';
import ImageWithTitle from '../components/imagewithtitle';
import img from '../img/visit.png';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import '../../styles/medical/medical-surgery.scss';

// アイコンインポート
import image1 from '../icons/外科.svg';
import image1hover from '../icons/外科ホバー.svg';
import image2 from '../icons/内科.svg';
import image2hover from '../icons/内科ホバー.svg';
import image3 from '../icons/胃腸科.svg';
import image3hover from '../icons/胃腸科ホバー.svg';
import image4 from '../icons/乳腺・甲状腺専門.svg';
import image4hover from '../icons/乳腺、甲状腺専門ホバー.svg';
import image5 from '../icons/整形外科.svg';
import image5hover from '../icons/整形外科ホバー.svg';
import image6 from '../icons/肛門科.svg';
import image6hover from '../icons/肛門科ホバー.svg';
import image7 from '../icons/訪問診療.svg';
import image7hover from '../icons/訪問診療ホバー.svg';
import image8 from '../icons/在宅支援.svg';
import image8hover from '../icons/在宅支援ホバー.svg';
import image9 from '../icons/icons-産業医.png';
import image9hover from '../icons/icons-産業医2.png';
import image10 from '../icons/icons-特殊診療.png';
import image10hover from '../icons/icons-特殊診療2.png';
import image11 from '../icons/icons-発熱.png';
import image11hover from '../icons/icons-発熱2.png';

function MedicalVisit () {
    return(
        <div className="medicalsurgery">
            <ImageWithTitle title="訪問診療"/>

            <section className="main">
                <div className="box">
                    <div className="img">
                        <img src={img}></img>
                    </div>
                      <p>医療には、外来医療、入院医療、在宅医療（①訪問診療、②往診）があります。<br />
                      <br />
                      当院では外来医療（病院や診療所の外来に通って受ける）および在宅医療（①患者様の自宅や施設などで受ける訪問診療）に対応しています。<br />
                      訪問診療と往診の違いについては、下記を参考にしてください。<br />
                      <br />
                      ①	訪問診療：疾病や傷病等の何らかの理由があり、通院が困難な方に対し、医師が予め診療の計画を立て、患者さんの同意の上、定期的に患者さんの自宅や施設に赴いて行う診療です。患者さんとの契約が必要となります。<br />
                      ⚫︎24時間体制で対応<br />
                      ⚫︎看取り往診<br />
                      ⚫︎緊急往診（患者様の要請や病状により随時対応）<br />
                      <br />
                      その他にも以下の処置を行なっています。<br />
                      (ア)	在宅酸素（人工呼吸器等）<br />
                      (イ)	気管切開<br />
                      (ウ)	点滴（中心静脈栄養、静脈点滴、皮下点滴）<br />
                      (エ)	経管栄養（胃ろう、経鼻、腸ろう等）、人工肛門、腎ろう、膀胱ろう<br />
                      (オ)	導尿<br />
                      (カ)	褥瘡処理<br />
                      (キ)	麻薬による疼痛管理<br />
                      <br />
                      ②	往診：患者様の依頼があり、医師が診療上必要と判断した時、予定外に患者様の自宅などに赴いて行う診療。患者様との契約は必要としません。<br />
                      </p>
              </div>
            </section>

            {/* 3. 科目別で探すセクション */}
            <section className="medical-top__departments">
                <h2 className="title__text">科目別で探す</h2>
                <div className="medical-top__buttonbox">
                <ButtonSubject 
              to="/medical-surgery" 
              imgSrc={image1}
              hoverImgSrc={image1hover}
            >
              外科
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-internal" 
              imgSrc={image2}
              hoverImgSrc={image2hover}
            >
              内科
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-gastroenterology" 
              imgSrc={image3}
              hoverImgSrc={image3hover}
            >
              胃腸科
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-orthopedics" 
              imgSrc={image5}
              hoverImgSrc={image5hover}
            >
              整形外科
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-proctology" 
              imgSrc={image6}
              hoverImgSrc={image6hover}
            >
              肛門科
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-visit" 
              imgSrc={image7}
              hoverImgSrc={image7hover}
            >
              訪問診療
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-specialized" 
              imgSrc={image4}
              hoverImgSrc={image4hover}
            >
              乳腺・甲状腺専門
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-fever" 
              imgSrc={image11}
              hoverImgSrc={image11hover}
            >
              発熱外来
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-industrialphysician" 
              imgSrc={image9}
              hoverImgSrc={image9hover}
            >
              産業医
            </ButtonSubject>
            <ButtonSubject 
              to="/medical-unique" 
              imgSrc={image10}
              hoverImgSrc={image10hover}
            >
              特殊診療
            </ButtonSubject>
          </div>
                <div className="medical-top__img">
                <img src={triangleMatrix} className="medical-top__img--topleft" />
                <img src={triangleMatrix} className="medical-top__img--bottomright" />
                </div>
            </section>

            <TreatmentSchedule />

            <MedicalInformation />
        </div>

    );

}

export default MedicalVisit;