// その他
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import DentalButton from '../components/dentalbuttton';
import Symptom from '../components/symptom.js';

function Dentalsymptoms12() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="その他" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            他の症状に当てはまらないお口のお悩みがあれば、ご相談ください。<br />
                            少しでも患者様のお口の健康と心身のストレス緩和のお役に立てれば幸いです。
                        </div>
                        <div className="button-wrapper">
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />
        </div>
    );


};

export default Dentalsymptoms12;