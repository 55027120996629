// 入れ歯を作りたい
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms4() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="入れ歯を作りたい" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            虫歯や歯周病などの理由で歯がない場合、食事が摂りにくくなったり、会話が難しくなる場合があります。<br />
                            歯の喪失は上記の症状の他に様々な弊害があります。<br />
                            喪失した部位の隣の歯が傾いてくることによる咬合障害や、噛み合う歯がないことによる歯の挺出、虫歯リスクの上昇、消化管の負担や栄養障害などが起こります。<br />
                            入れ歯の作成には最低でも4〜5回の来院が必要になります。<br />
                            食事はみなさんの健康生活を支える上で、非常に大切な行為です。豊かな食生活を送れるよう、歯科医院を受診することをお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />

        </div>
    );


};

export default Dentalsymptoms4;