/*ヘッダー*/
import React, { useState, useEffect, useRef } from "react";
import '../styles/header.scss';
import { Link } from 'react-router-dom';
import logo from './icons/logo.png';
import SlideCard from './components/slidecard.js';
import lineicon from "./icons/line-icon.png";
import SlideCardsp from '../contents/components/slidecardsp';

const Header = () => {
  const menuItems = [
    { title: 'トップ', link: '/' },
    { title: '医科', link: '/medical-top',
      subMenu: [
        { title: '内科', link: '/medical-internal' },
        { title: '外科', link: '/medical-surgery' },
        { title: '胃腸科', link: '/medical-gastroenterology' },
        { title: '整形外科', link: '/medical-orthopedics' },
        { title: '肛門科', link: '/medical-proctology' },
        { title: '訪問診療', link: '/medical-visit' },
        { title: '発熱外来', link: '/medical-fever' },
        { title: '乳腺・甲状腺専門外来', link: '/medical-specialized' },
        { title: '院内感染対策', link: '' },
        { title: '産業医', link: '/medical-industrialphysician' },
      ]
    },
    { title: '歯科', link: '/dental-top',
      subMenu: [
        { title: '診療のご案内', link: '/dental-information' },
        { title: '一般歯科', link: '/dental-general' },
        { title: '症状別で探す', link: '/dental-symptoms1' },
      ]

    },
    { title: '当院紹介', link: '/dental-introduction',
      subMenu:[
        { title: '医科　施設・設備紹介', link: '/' },
        { title: '歯科　施設・設備紹介', link: '/dental-introduction' },
      ]
    },
    { title: '院長ご挨拶', link: '/greeting' },
    { title: '採用', link: '/dental-recruit' },
    { title: 'お問い合わせ', link: '/contact' }
  ];

  const [activeLink, setActiveLink] = useState(""); 
  const [anchorEl,setAnchorEl] =useState(null);

  const menuItemRefs = useRef([]);

  useEffect(() => {
    menuItemRefs.current = menuItemRefs.current.slice(0, menuItems.length);
  }, [menuItems]);


  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  
  const handleHoverEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleHoverLeave = (event) => {
    setAnchorEl(null);
  };
  
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
      setIsActive(!isActive);
  };

  const closeMenu = () => {
      setIsActive(false);
  };

  return (
      <header className="header">
        <img src={logo} alt="Logo" className="header__logo" />
        <div className="for-pc">
          <nav className="header__nav">
            <ul className="header__menu-list">
              {menuItems.map((item, index) => (
                <li 
                  key={item.title} 
                  className="header__menu-item"
                  // 各メニューアイテムにrefを割り当てます。
                  ref={el => menuItemRefs.current[index] = el}
                  onMouseEnter={handleHoverEnter} 
                  onMouseLeave={handleHoverLeave}
                >
                  <Link 
                    to={item.link} 
                    onClick={() => handleLinkClick(item.link)}
                    className={activeLink === item.link ? 'active' : ''}
                  >
                    {item.title}
                  </Link>
                  {item.subMenu && item.subMenu.length > 0 && anchorEl === menuItemRefs.current[index] && (
                    <div className="header__submenu-box">
                    <ul className="header__submenu-list">
                      {item.subMenu.map(subItem => (
                        <li key={subItem.title} className="header__submenu-item">
                          <Link 
                            to={subItem.link} 
                            onClick={() => handleLinkClick(subItem.link)}
                            className={activeLink === subItem.link ? 'active' : ''}
                          >
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    </div>
                  )}
                </li>
                ))}
            </ul>
          </nav>
          <div className="lineicon">
            <a href="https://lin.ee/2DYRtiD">
              <img src={lineicon} />
            </a>
          </div>
          <SlideCard />
        </div>
      <div className="for-sp">
              <div className={`hamburger ${isActive ? 'is-active' : ''}`} onClick={toggleMenu}>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
              </div>

              <nav className={`navigation ${isActive ? 'is-active' : ''}`}>
                  <ul>
                  {menuItems.map((item, index) => (
                <li 
                  key={item.title} 
                  className="header__menu-item"
                  // 各メニューアイテムにrefを割り当てます。
                  ref={el => menuItemRefs.current[index] = el}
                  onMouseEnter={handleHoverEnter} 
                  onMouseLeave={handleHoverLeave}
                >
                  <Link 
                    to={item.link} 
                    onClick={() => handleLinkClick(item.link)}
                    className={activeLink === item.link ? 'active' : ''}
                  >
                    {item.title}
                  </Link>
                  {item.subMenu && item.subMenu.length > 0 && anchorEl === menuItemRefs.current[index] && (
                    <div className="header__submenu-box">
                    <ul className="header__submenu-list">
                      {item.subMenu.map(subItem => (
                        <li key={subItem.title} className="header__submenu-item">
                          <Link 
                            to={subItem.link} 
                            onClick={() => handleLinkClick(subItem.link)}
                            className={activeLink === subItem.link ? 'active' : ''}
                          >
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    </div>
                  )}
                </li>
                ))}
                  </ul>
              </nav>
              <SlideCardsp />
      </div>
    </header>
    
  );
};




export default Header;