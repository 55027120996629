/**発熱外来 */
import React from 'react';
import TreatmentSchedule from '../components/schedule';
import MedicalInformation from '../components/medicalinformation';
import ImageWithTitle from '../components/imagewithtitle';
import img from '../img/fever.JPG';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import '../../styles/medical/medical-surgery.scss';
import ButtonBox from '../components/buttonbox';

function MedicalFever () {
    return(
        <div className="medicalsurgery">
            <ImageWithTitle title="発熱外来"/>

            <section className="main">
                <div className="box">
                    <div className="img">
                        <img src={img}></img>
                    </div>
                    <p>当院では、発熱や風邪症状のある方は発熱外来にて対応しております。<br />
                        下記の症状がある方は事前にお電話にて<span className="bald">予約</span>の上来院をお願いいたします。<br />
                        <span className="red">当院の駐車場に着きましたら、受付にお電話でご連絡ください。</span><br />
                        ・３７度以上の発熱<br />
                        ・数日前から風邪症状が続いている<br />
                        ・咽頭痛<br />
                        ・咳<br />
                        ・倦怠感<br />
                        ・嗅覚、味覚の異常<br />
                        ・発熱を伴う下痢
                    </p>
                </div>
                <div className="fever">
                    <p>
                        発熱外来検査<br />
                        PCR：<br />
                        ID-NOW（NEAR法）：厚生労働省承認の遺伝子検査です。PCR検査とほぼ同じ精度で、約１５分で検査結果がでます。迅速性、正確性によりこちらを採用しています。<br />
                        抗原検査：必要により季節性インフルエンザ、新型コロナのPCR、抗原検査を行います。
                    </p>
                </div>
            </section>

            {/* 3. 科目別で探すセクション */}
            <section className="medical-top__departments">
                <h2 className="title__text">科目別で探す</h2>
                <ButtonBox />
                <div className="medical-top__img">
                <img src={triangleMatrix} className="medical-top__img--topleft" />
                <img src={triangleMatrix} className="medical-top__img--bottomright" />
                </div>
            </section>

            <TreatmentSchedule />

            <MedicalInformation />
        </div>

    );

}

export default MedicalFever;