/**一般歯科 */
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/dental/dental-general.scss';
import smalldecay from '../img/小さい虫歯.JPG';
import bigdecay from '../img/大きい虫歯.JPG';
import Dentalpractice from '../components/dentalpractice.js';
import Specialtreatment from '../components/specialtreatment.js';
import DentalSchedule from '../components/dental-schedule.js';
import Access from '../components/access.js';

function DentalGeneral (){
    
    return(
        <div className="dentalgeneral">
            <ImageWithTitle title="虫歯治療のご案内"/>

            <div className="for-pc">
                <div className="main">
                    <div class="main-contentsbox">
                        <div class="imgwrapper">
                            <img src={smalldecay}></img>
                        </div>
                        <div className="main-textwrapper">
                            <h4 className="main-textwrapper-title">
                                小さな虫歯
                            </h4>
                            <p className="main-textwrapper-caption">
                                虫歯を削った後、プラスチックの白い材料を使って修復します。<br />
                                使う材料は、患者様の歯の色に近い色のものを使わせていただきます。<br />
                                基本的には、歯の形が大きく変わることはありません。<br />
                                虫歯を削る際は、必要に応じて麻酔を使用させていただくことがあります。

                            </p>

                        </div>

                    </div>
                    <div class="main-contentsbox">
                        <div className="main-textwrapper">
                            <h4 className="main-textwrapper-title">
                                大きな虫歯
                            </h4>
                            <p className="main-textwrapper-caption">
                                歯を削った後、部分的な詰め物を使用して修復します。<br />
                                型取りが必要となりますので、１本（１箇所）につき、最低２回の治療回数が必要となります。<br />
                                詰め物の種類は保険のもの（銀歯）と自費のもの（セラミック等）と種類が複数あり、患者様ご自身で選んでいただくことが可能です。<br />
                                虫歯を削る際は、麻酔を使用させていただきます。

                            </p>

                        </div>
                        <div class="imgwrapper">
                            <img src={bigdecay}></img>
                        </div>

                    </div>

                </div>
            </div>
            <div className="for-sp">
                <div className="main">
                    <div class="main-contentsbox">
                        <div class="imgwrapper">
                            <img src={smalldecay}></img>
                        </div>
                        <div className="main-textwrapper">
                            <h4 className="main-textwrapper-title">
                                小さな虫歯
                            </h4>
                            <p className="main-textwrapper-caption">
                                虫歯を削った後、プラスチックの白い材料を使って修復します。<br />
                                使う材料は、患者様の歯の色に近い色のものを使わせていただきます。<br />
                                基本的には、歯の形が大きく変わることはありません。<br />
                                虫歯を削る際は、必要に応じて麻酔を使用させていただくことがあります。

                            </p>

                        </div>

                    </div>
                    <div class="main-contentsbox">
                        <div class="imgwrapper">
                            <img src={bigdecay}></img>
                        </div>
                        <div className="main-textwrapper">
                            <h4 className="main-textwrapper-title">
                                大きな虫歯
                            </h4>
                            <p className="main-textwrapper-caption">
                                歯を削った後、部分的な詰め物を使用して修復します。<br />
                                型取りが必要となりますので、１本（１箇所）につき、最低２回の治療回数が必要となります。<br />
                                詰め物の種類は保険のもの（銀歯）と自費のもの（セラミック等）と種類が複数あり、患者様ご自身で選んでいただくことが可能です。<br />
                                虫歯を削る際は、麻酔を使用させていただきます。

                            </p>

                        </div>

                    </div>

                </div>
            </div>

            <Dentalpractice />
            
            <Specialtreatment />
            
            <DentalSchedule />
            
            <Access />


        </div>

    );



}

export default DentalGeneral;