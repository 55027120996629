import React from 'react';
import '../../styles/medical/medical-surgery.scss';
import ButtonSubject from '../components/button-subject.js';

// アイコンインポート
import image1 from '../icons/外科.svg';
import image1hover from '../icons/外科ホバー.svg';
import image2 from '../icons/内科.svg';
import image2hover from '../icons/内科ホバー.svg';
import image3 from '../icons/胃腸科.svg';
import image3hover from '../icons/胃腸科ホバー.svg';
import image4 from '../icons/乳腺・甲状腺専門.svg';
import image4hover from '../icons/乳腺、甲状腺専門ホバー.svg';
import image5 from '../icons/整形外科.svg';
import image5hover from '../icons/整形外科ホバー.svg';
import image6 from '../icons/肛門科.svg';
import image6hover from '../icons/肛門科ホバー.svg';
import image7 from '../icons/訪問診療.svg';
import image7hover from '../icons/訪問診療ホバー.svg';
import image8 from '../icons/在宅支援.svg';
import image8hover from '../icons/在宅支援ホバー.svg';
import image9 from '../icons/icons-産業医.png';
import image9hover from '../icons/icons-産業医2.png';
import image10 from '../icons/icons-特殊診療.png';
import image10hover from '../icons/icons-特殊診療2.png';
import image11 from '../icons/icons-発熱.png';
import image11hover from '../icons/icons-発熱2.png';

const ButtonBox = () => {

    return (
        <div className="medical-top__buttonbox">
            <ButtonSubject 
            to="/medical-surgery" 
            imgSrc={image1}
            hoverImgSrc={image1hover}
            >
            外科
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-internal" 
            imgSrc={image2}
            hoverImgSrc={image2hover}
            >
            内科
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-gastroenterology" 
            imgSrc={image3}
            hoverImgSrc={image3hover}
            >
            胃腸科
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-orthopedics" 
            imgSrc={image5}
            hoverImgSrc={image5hover}
            >
            整形外科
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-proctology" 
            imgSrc={image6}
            hoverImgSrc={image6hover}
            >
            肛門科
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-visit" 
            imgSrc={image7}
            hoverImgSrc={image7hover}
            >
            訪問診療
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-specialized" 
            imgSrc={image4}
            hoverImgSrc={image4hover}
            >
            乳腺・甲状腺専門
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-fever" 
            imgSrc={image11}
            hoverImgSrc={image11hover}
            >
            発熱外来
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-industrialphysician" 
            imgSrc={image9}
            hoverImgSrc={image9hover}
            >
            産業医
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-injection" 
            imgSrc={image10}
            hoverImgSrc={image10hover}
            >
            予防接種
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-healthcheck" 
            imgSrc={image10}
            hoverImgSrc={image10hover}
            >
            健康診断
            </ButtonSubject>
            <ButtonSubject 
            to="/medical-unique" 
            imgSrc={image10}
            hoverImgSrc={image10hover}
            >
            その他診療
            </ButtonSubject>
        </div>
    );
}

export default ButtonBox;