// 舌や頬にできものや痺れ
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import Button from '../components/button';
import Symptom from '../components/symptom.js';
import DentalButton from '../components/dentalbuttton';

function Dentalsymptoms6() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="舌や頬にできものや痺れ" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            口腔内の粘膜に出来物や、痺れ、ヒリヒリ感などの症状がある場合は、口腔粘膜疾患の可能性が疑われます。<br />
                            口内炎、舌・頬粘膜咬傷、扁平苔癬、白板症を含めてその他に多くの粘膜疾患が挙げられます。<br />
                            中には悪性腫瘍のサインとなるものもあるため、上記症状があるようなら、早めに受診することをお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />
        </div>
    );


};

export default Dentalsymptoms6;