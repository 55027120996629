import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './contents/Header.js';
import Footer from './contents/footer.js';
import MainContents from './contents/top.js';
import Greeting from './contents/greeting.js';
import NavigationMenu from './contents/navigationmenu.js';
import ScrollToTop from './contents/components/scrolltotop.js';
import ContactForm from './contents/contact.js';
import RecruitForm from './contents/recruitform.js';

/*歯科インポート */
import DentalContact from './contents/dental/dental-contact.js';
import DentalGeneral from './contents/dental/dental-general.js';
import DentalInformation from './contents/dental/dental-information.js';
import DentalIntroduction from './contents/dental/dental-introduction.js';
import DentalRecruit from './contents/dental/dental-recruit.js';
import DentalRecruitForm from './contents/dental/dental-recruitform.js';
import DentalSpecialized from './contents/dental/dental-specialized.js';
import DentalTop from './contents/dental/dental-top.js';
import Dentalsymptoms1 from './contents/dental/dental-symptoms1';
import Dentalsymptoms2 from './contents/dental/dental-symptoms2';
import Dentalsymptoms3 from './contents/dental/dental-symptoms3';
import Dentalsymptoms4 from './contents/dental/dental-symptoms4';
import Dentalsymptoms5 from './contents/dental/dental-symptoms5';
import Dentalsymptoms6 from './contents/dental/dental-symptoms6';
import Dentalsymptoms7 from './contents/dental/dental-symptoms7';
import Dentalsymptoms8 from './contents/dental/dental-symptoms8';
import Dentalsymptoms9 from './contents/dental/dental-symptoms9';
import Dentalsymptoms10 from './contents/dental/dental-symptoms10';
import Dentalsymptoms11 from './contents/dental/dental-symptoms11';
import Dentalsymptoms12 from './contents/dental/dental-symptoms12';
import Dentalsymptoms13 from './contents/dental/dental-symptoms13';
import DentalImplant from './contents/dental/dental-implant';
import DentalFirstImpression from './contents/dental/dental-firstimpression';
import DentalPeriodontal from './contents/dental/dental-periodontal';
import DentalRelapse from './contents/dental/dental-relapse';
import DentalCorrection from './contents/dental/dental-correction';
import DentalWhitening from './contents/dental/dental-whitening';
import DentalAesthetic from './contents/dental/dental-aesthetic';
import DentalLasertreatment from './contents/dental/dental-lasertreatment';

/*医科インポート */
import MedicalTop from './contents/medical/medical-top.js';
import MedicalFever from './contents/medical/medical-fever.js';
import MedicalGastroenterology from './contents/medical/medical-gastroenterology.js';
import MedicalHomeSupport from './contents/medical/medical-homesupport.js';
import MedicalInternal from './contents/medical/medical-internal.js';
import MedicalIntroduction from './contents/medical/medical-introduction.js';
import MedicalOrthopedics from './contents/medical/medical-orthopedics.js';
import MedicalProctology from './contents/medical/medical-proctology.js';
import MedicalSpecialized from './contents/medical/medical-specialized.js';
import MedicalSurgery from './contents/medical/medical-surgery.js';
import MedicalVisit from './contents/medical/medical-visit.js';
import MedicalIndustrialphysician from './contents/medical/medical-industrialphysician.js';
import MedicalUnique from './contents/medical/medical-unique';
import MedicalInjection from './contents/components/medicalinjection';
import MedicalHealthcheck from './contents/components/medicalhealthcheck';

/*告知ページインポート */
import Vaccination240401 from './contents/informationsite/vaccination240401';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<MainContents />} />
            <Route path="/greeting" element={<Greeting />} />
            <Route path="/navigationmenu" element={<NavigationMenu />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/recruitform" element={<RecruitForm />} />

            {/* 歯科ルート */}
            <Route path="/dental-top" element={<DentalTop />} />
            <Route path="/dental-contact" element={<DentalContact />} />
            <Route path="/dental-general" element={<DentalGeneral />} />
            <Route path="/dental-information" element={<DentalInformation />} />
            <Route path="/dental-introduction" element={<DentalIntroduction />} />
            <Route path="/dental-recruit" element={<DentalRecruit />} />
            <Route path="/dental-recruitform" element={<DentalRecruitForm />} />
            <Route path="/dental-specialize" element={<DentalSpecialized />} />
            <Route path="/dental-symptoms1" element={<Dentalsymptoms1 />} />
            <Route path="/dental-symptoms2" element={<Dentalsymptoms2 />} />
            <Route path="/dental-symptoms3" element={<Dentalsymptoms3 />} />
            <Route path="/dental-symptoms4" element={<Dentalsymptoms4 />} />
            <Route path="/dental-symptoms5" element={<Dentalsymptoms5 />} />
            <Route path="/dental-symptoms6" element={<Dentalsymptoms6 />} />
            <Route path="/dental-symptoms7" element={<Dentalsymptoms7 />} />
            <Route path="/dental-symptoms8" element={<Dentalsymptoms8 />} />
            <Route path="/dental-symptoms9" element={<Dentalsymptoms9 />} />
            <Route path="/dental-symptoms10" element={<Dentalsymptoms10 />} />
            <Route path="/dental-symptoms11" element={<Dentalsymptoms11 />} />
            <Route path="/dental-symptoms12" element={<Dentalsymptoms12 />} />
            <Route path="/dental-symptoms13" element={<Dentalsymptoms13 />} />
            <Route path="/dental-implant" element={<DentalImplant />} />
            <Route path="/dental-firstimpression" element={<DentalFirstImpression />} />
            <Route path="/dental-periodontal" element={<DentalPeriodontal />} />
            <Route path="/dental-relapse" element={<DentalRelapse />} />
            <Route path="/dental-correction" element={<DentalCorrection />} />
            <Route path="/dental-whitening" element={<DentalWhitening />} />
            <Route path="/dental-aesthetic" element={<DentalAesthetic />} />
            <Route path="/dental-lasertreatment" element={<DentalLasertreatment />} />

            {/* 医科ルート */}
            <Route path="/medical-top" element={<MedicalTop />} />
            <Route path="/medical-fever" element={<MedicalFever />} />
            <Route path="/medical-gastroenterology" element={<MedicalGastroenterology />} />
            <Route path="/medical-homesupport" element={<MedicalHomeSupport />} />
            <Route path="/medical-internal" element={<MedicalInternal />} />
            <Route path="/medical-introduction" element={<MedicalIntroduction />} />
            <Route path="/medical-orthopedics" element={<MedicalOrthopedics />} />
            <Route path="/medical-proctology" element={<MedicalProctology />} />
            <Route path="/medical-specialized" element={<MedicalSpecialized />} />
            <Route path="/medical-surgery" element={<MedicalSurgery />} />
            <Route path="/medical-visit" element={<MedicalVisit />} />
            <Route path="/medical-industrialphysician" element={<MedicalIndustrialphysician />} />
            <Route path="/medical-unique" element={<MedicalUnique />} />
            <Route path="/medical-injection" element={<MedicalInjection />} />
            <Route path="/medical-healthcheck" element={<MedicalHealthcheck />} />

            {/* 告知ページルート */}
            <Route path="/information-01" element={<Vaccination240401 />} />

          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

