import React, { useState } from 'react';
import '../../styles/components/medicalinformation.scss';
import triangleLine from '../icons/triangleline.svg';
import Button from './externallink';

const MedicalHealthcheck = () => {

    return(
        <div className="medicalinformation">
        <section className="title">
            <div className="title">
                <img className="title__picture" src={triangleLine} alt="" />
                <h2 className="title__text">健康診断について</h2>
            </div>
        </section>

        <section className="column">
            <h3>健康診断について</h3>
                <p>当院で実施している下記の健康診断は予約制となっておりますので、受付窓口またはお電話にて事前にご予約いただきますようお願いいたします。</p>
                <p>
                    ・個人のお申込みによる健康診断<br />
                    ・事業所単位でのお申込みによる企業の健康診断<br />
                    ・高崎市の特定健診<br />
                    ①被用者保険加入者の特定健診（※集合Bのみ）<br />
                    ②国保特定健診<br />
                    ③後期高齢者健診<br />
                </p>
                <p>
                    高崎市健康づくり受診券については下記個別健診をお受けしています。<br />
                    ・胃がん（リスク）検診<br />
                    ・ピロリ検診<br />
                    ・胸部X線検査<br />
                    ・かくたん検査<br />
                    ・大腸がん検診<br />
                    ・前立腺がん検診<br />
                    ・骨量検診<br />
                    ・肝炎ウイルス検診<br />
                </p>
            
        </section>
    </div>
    );
}

export default MedicalHealthcheck;