import React, { useState } from 'react';
import "../styles/contact.scss";
import ImageWithTitle from '../contents/components/imagewithtitle';

const ContactForm = () => {
  const [title1, setTitle1] = useState('');
  const [title2, setTitle2] = useState('');
  const [title3, setTitle3] = useState('');

  const handleFormSubmit = () => {
    // フォームが送信されたときの処理
    // ここでメールを送信するか、API経由でサーバーにデータを送信するなどの処理を行います

    // 例えば、コンソールに出力する場合
    console.log(`氏名: ${title1}, 電話番号: ${title2}, 問い合わせ内容: ${title3}`);

    // メール送信の場合は、サーバーにAPIリクエストを送信するなどの処理を追加
  };

  return (
    <div className="contact">
      <div>
        <ImageWithTitle title="お問い合わせ" />
      </div>
      
      <div className="contact__profilebox">
        <table className="contact__profile-table">
        <tr>
            <td className="contact__profile-table--left">氏名</td>
            <td className="contact__profile-table--right">
            <label>
              <input type="text" value={title1} onChange={(e) => setTitle1(e.target.value)} />
              </label>
            </td>
        </tr>
        <tr>
            <td className="contact__profile-table--left">電話番号</td>
            <td className="contact__profile-table--right">
              <label>
                <input type="text" value={title2} onChange={(e) => setTitle2(e.target.value)} />
              </label>
            </td>
        </tr>
        <tr className="contact-detail">
            <td className="contact__profile-table--left">問い合わせ内容</td>
            <td className="contact__profile-table--right">
              <label>
                <input type="text" value={title3} onChange={(e) => setTitle3(e.target.value)} />
              </label>
            </td>
        </tr>
        <tr>
            <td className="contact__profile-table--left"> 項目</td>
            <td className="contact__profile-table--right">

            </td>
        </tr>
        </table>
    </div>

      <button onClick={handleFormSubmit}>フォーム送信</button>
    </div>
  );
};

export default ContactForm;



