// 歯が痛い　しみる
import React, { useState , useRef } from 'react';
import '../../styles/dental/dental-symptoms.scss';
import ImageWithTitle from '../components/imagewithtitle';
import Toothache from '../img/toothache.jpeg';
import DentalButton from '../components/dentalbuttton';
import Symptom from '../components/symptom.js';

function Dentalsymptoms1() {
    return(
        <div className="dental-symptoms">
            <section className="top">
                <div>
                    <ImageWithTitle title="歯が痛い、しみる" />
                </div>
            </section>
            <section className="contents">
                <div className="container">
                    <div className="container-img">
                        {/* <img src={Toothache} /> */}
                    </div>
                    <div className="container-box">
                        <div className="container-box__text">
                            冷たい物でしみたり、歯が痛む、ズキズキする原因として、虫歯・知覚過敏が考えられます。<br />
                            虫歯の場合、症状が軽度であれば即日治療にて対応することができますが、放置すると症状が進行し、治療回数が増えたり、神経の処置（根管治療）に発展する可能性があります。<br />
                            知覚過敏の場合は軽度であれば経過観察や即日治療で対応出来ますが、放置すると神経の処置（根管治療）に発展する可能性もあります。<br />
                            いずれの場合も、早めに歯科医院を受診することをお勧めします。
                        </div>
                        <div>
                            <DentalButton className="image-links__button" to="/dental-top">予約する</DentalButton>
                        </div>                     
                    </div>
                </div>

            </section>

            <Symptom />

        </div>
    );


};

export default Dentalsymptoms1;
