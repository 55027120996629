/**院長挨拶 */
import React, { useState , useRef } from 'react';
import '../styles/greeting.scss';
import '../styles/medical/medical-top.scss';
import '../styles/dental/dental-top.scss';
import ImageWithTitle from './components/imagewithtitle';
import DentalDr from './img/dentaldr.JPG';
import MedicalDr from './img/medicaldr.JPG';
import triangleLine from './icons/triangleline.svg';
import Access from './components/access';



function Greeting () {
    return(
        <div className="greeting">
            <ImageWithTitle title="院長ご挨拶" />
            <section className="medical-top__greetings">
                <div className="for-pc">
                    <div className="title">
                            <img className="title__picture" src={triangleLine} alt="" />
                            <h2 className="title__text">医科院長ご挨拶</h2>
                    </div>
                    <div className="medical-top__profilebox">
                        <table className="medical-top__profile-table">
                        <tr>
                            <td className="medical-top__profile-table--left">プロフィール</td>
                            <td className="medical-top__profile-table--right">経歴
                                <br />
                                <br />
                                S51年
                                <br />
                                北里大学医学部卒業
                                <br />
                                <br />
                                S51～57年
                                <br />
                                群馬大学附属病院勤務
                                <br />
                                <br />
                                S58年
                                <br />
                                開業<span>現在に至る</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="medical-top__profile-table--left">所属学会／認定医</td>
                            <td className="medical-top__profile-table--right">日本医師会認定産業医<br />難病指定医</td>
                        </tr>
                        <tr>
                            <td className="medical-top__profile-table--left">専門としている診療科目</td>
                            <td className="medical-top__profile-table--right">
                                ・乳腺・甲状腺科
                                <br />
                                ・在宅訪問診療
                                <br />
                                ・往診
                            </td>
                        </tr>
                        <tr>
                            <td className="medical-top__profile-table--left"> ご挨拶</td>
                            <td className="medical-top__profile-table--right">私たちが心がけておりますのは、地域に密着した、皆様に信頼されるかかりつけ医です。<br />
                            患者様１人ひとりの健康上の悩みや不安に真摯に向き合い、納得いただいたうえで治療を受けていただけるよう、わかりやすい、丁寧な説明を心がけております。<br />
                            何処の医療機関を受診したらよいかわからない方でも、受診していただければ、適切な医療機関をご紹介いたします。<br />
                            通院困難な方には訪問診療(在宅医療)も行っております。<br />
                            どうぞお気軽にご相談、ご来院ください。</td>
                        </tr>
                        </table>
                        <div className="medical-top__profile-img">
                        <img src={MedicalDr} />
                        </div>
                    </div>
                </div>
                <div className="for-sp">
                <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">医科院長ご挨拶</h2>
                </div>
                <div className="medical-top__profilebox">
                    <div className="medical-top__profile-img">
                    <img src={MedicalDr} />
                    </div>
                    <table className="medical-top__profile-table">
                    <tr>
                        <td className="medical-top__profile-table--left">プロフィール</td>
                        <td className="medical-top__profile-table--right">経歴
                            <br />
                            <br />
                            S51年
                            <br />
                            北里大学医学部卒業
                            <br />
                            <br />
                            S51～57年
                            <br />
                            群馬大学附属病院勤務
                            <br />
                            <br />
                            S58年
                            <br />
                            開業<span>現在に至る</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="medical-top__profile-table--left">所属学会／認定医</td>
                        <td className="medical-top__profile-table--right">日本医師会認定産業医</td>
                    </tr>
                    <tr>
                        <td className="medical-top__profile-table--left">専門としている診療科目</td>
                        <td className="medical-top__profile-table--right">・甲状腺専門外来
                            <br />
                            ・在宅療養支援診療所
                            <br />
                            ・在宅訪問診療
                            <br />
                            ・往診
                        </td>
                    </tr>
                    </table>
                </div>
                </div>
            </section>

            <section className="greetings">
                <div className="for-pc">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">歯科院長ご挨拶</h2>
                    </div>
                    <div className="greetings__profilebox">
                        <div className="greetings__profile-img">
                            <img src={DentalDr} />
                        </div>
                        <table className="greetings__profile-table">
                            <tr>
                            <td className="medical-top__profile-table--left">プロフィール</td>
                            <td className="medical-top__profile-table--right">経歴
                                <br />
                                群馬県立高崎高等学校　卒業
                                <br />
                                岩手医科大学歯学部　卒業
                                <br />
                                <br />
                                岩手医科大学大学院歯学研究科<br />
                                補綴・インプラント学分野修了（歯学博士）
                                <br />
                                同大学　補綴・インプラント学講座　入局
                                <br />
                                <br />
                                医療法人翠聖会　パール歯科医院　勤務
                                <br />
                                <br />
                                県央クリニックにて歯科開設（2020年4月）
                                <br />
                                県央医科歯科クリニック開業
                            </td>
                            </tr>
                            <tr>
                            <td className="medical-top__profile-table--left">所属学会／認定医</td>
                            <td className="medical-top__profile-table--right">
                                日本口腔インプラント学会<br />
                                日本補綴歯科学会
                            </td>
                            </tr>
                            <tr>
                            <td className="medical-top__profile-table--left">ご挨拶</td>
                            <td className="medical-top__profile-table--right">私たちの日常生活において食事を摂るという行為は、健康な生活を送る上で最も重要な行為の一つです。お口の中の健康を保つことで、皆様の生活の質の向上に少しでもお役に立ちたいと思っています。
    皆様の声に耳を傾け、しっかりと説明させていただき、精一杯頑張って地域医療に貢献していきたいと思います。
    よろしくお願い致します。</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="for-sp">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">医科院長ご挨拶</h2>
                    </div>
                    <div className="greetings__profilebox">
                        <table className="greetings__profile-table">
                            <tr>
                            <td className="medical-top__profile-table--left">プロフィール</td>
                            <td className="medical-top__profile-table--right">経歴
                                <br />
                                <br />
                                S51年
                                <br />
                                北里大学医学部卒業
                                <br />
                                <br />
                                S51～57年
                                <br />
                                群馬大学附属病院勤務
                                <br />
                                <br />
                                S58年
                                <br />
                                開業<span>現在に至る</span>
                            </td>
                            </tr>
                            <tr>
                            <td className="medical-top__profile-table--left">所属学会／認定医</td>
                            <td className="medical-top__profile-table--right">日本医師会認定産業医</td>
                            </tr>
                            <tr>
                            <td className="medical-top__profile-table--left">専門としている診療科目</td>
                            <td className="medical-top__profile-table--right">・甲状腺専門外来
                                <br />
                                ・在宅療養支援診療所
                                <br />
                                ・在宅訪問診療
                                <br />
                                ・往診
                            </td>
                            </tr>
                            <tr>
                            <td className="medical-top__profile-table--left">ご挨拶</td>
                            <td className="medical-top__profile-table--right">私たちの日常生活において食事を摂るという行為は、健康な生活を送る上で最も重要な行為の一つです。お口の中の健康を保つことで、皆様の生活の質の向上に少しでもお役に立ちたいと思っています。
    皆様の声に耳を傾け、しっかりと説明させていただき、精一杯頑張って地域医療に貢献していきたいと思います。
    よろしくお願い致します。</td>
                            </tr>
                        </table>
                        <div className="greetings__profile-img">
                            <img src={DentalDr} />
                        </div>
                    </div>
                </div>
            </section>

            <Access />

        </div>

    );

}

export default Greeting;