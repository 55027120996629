import React, { useState } from 'react';
import "../styles/contact.scss";
import ImageWithTitle from '../contents/components/imagewithtitle';

const Recruitform = () => {
  const [recruittitle1, setrecruittitle1] = useState('');
  const [recruittitle2, setrecruittitle2] = useState('');
  const [recruittitle3, setrecruittitle3] = useState('');

  const handleFormSubmit = () => {
    // フォームが送信されたときの処理
    // ここでメールを送信するか、API経由でサーバーにデータを送信するなどの処理を行います

    // 例えば、コンソールに出力する場合
    console.log(`氏名: ${recruittitle1}, 電話番号: ${recruittitle2}, 問い合わせ内容: ${recruittitle3}`);

    // メール送信の場合は、サーバーにAPIリクエストを送信するなどの処理を追加
  };

  return (
    <div className="contact">
      <div>
        <ImageWithTitle title="リクルートフォーム" />
      </div>
      
      <div className="contact__profilebox">
        <table className="contact__profile-table">
        <tr>
            <td className="contact__profile-table--left">氏名</td>
            <td className="contact__profile-table--right">
            <label>
              <input type="text" value={recruittitle1} onChange={(e) => setrecruittitle1(e.target.value)} />
              </label>
            </td>
        </tr>
        <tr>
            <td className="contact__profile-table--left">電話番号</td>
            <td className="contact__profile-table--right">
              <label>
                <input type="text" value={recruittitle2} onChange={(e) => setrecruittitle2(e.target.value)} />
              </label>
            </td>
        </tr>
        <tr className="contact-detail">
            <td className="contact__profile-table--left">志望動機</td>
            <td className="contact__profile-table--right">
              <label>
                <input type="text" value={recruittitle3} onChange={(e) => setrecruittitle3(e.target.value)} />
              </label>
            </td>
        </tr>
        <tr>
            <td className="contact__profile-table--left">項目</td>
            <td className="contact__profile-table--right">
            </td>
        </tr>
        </table>
    </div>

      <button onClick={handleFormSubmit}>フォーム送信</button>
    </div>
  );
};

export default Recruitform;